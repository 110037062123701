import React from 'react';
import { Box, Typography } from "@mui/material"

export const Privacy = () => {

    return (
        <Box sx={{maxWidth: 800, padding: 1, margin: 'auto', lineHeight: 1.6}}>
            <h1>Privacy Policy</h1>
            <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or use our website (Hack in a Box) to share comments, ideas, and votes in Hackathons</p>
            <p><strong>1. Information We Collect</strong></p>
            <p>When you use our website, we collect certain information about you to provide and improve our services. This information may include:</p>
            <ul>
                <li>Personal Information: When you create an account, we collect your name, email address, and password.</li>
                <li>Hackathon Information: We collect information you provide in the form of text regarding your ideas, comments, and other data</li>
                <li>Usage Information: We collect information about how you interact with our website, such as the pages you visit, the features you use, and the actions you take.</li>
            </ul>
            <p><strong>2. How We Use Your Information</strong></p>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide and personalize our services.</li>
                <li>Manage your account and communicate with you.</li>
                <li>Improve and optimize our website's performance and user experience.</li>
                <li>Analyze usage trends and patterns to enhance our services and develop new features.</li>
                <li>Comply with legal obligations and protect our rights.</li>
            </ul>
            <p><strong>3. Data Sharing and Disclosure</strong></p>
            <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you, subject to appropriate confidentiality and security measures.</p>
            <p>We may also disclose your information in response to valid legal requests, to comply with applicable laws and regulations, to enforce our policies, or to protect the rights, property, or safety of ourselves or others.</p>
            <p><strong>4. Data Retention</strong></p>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
            <p><strong>5. Security</strong></p>
            <p>We are committed to protecting the security of your information and have implemented appropriate technical and organizational measures to safeguard it against unauthorized access, disclosure, alteration, and destruction.</p>
            <p><strong>6. Your Rights</strong></p>
            <p>You have certain rights regarding your personal information, including the right to access, correct, update, or delete it. You may also have the right to object to or restrict certain processing activities and to receive a copy of your data in a structured, machine-readable format.</p>
            <p><strong>7. Children's Privacy</strong></p>
            <p>Our website is not intended for children under the age of 13, and we do not knowingly collect personal information from individuals under 13 years of age. If we become aware that we have inadvertently collected such information, we will take steps to delete it as soon as possible.</p>
            <p><strong>8. Changes to This Privacy Policy</strong></p>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the new policy on this page.</p>
            <p><strong>9. Contact Us</strong></p>
            <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:hackathons-help@persera.ca">hackathons-help@persera.ca</a>.</p>
            <p>By using our website, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not access or use our website.</p>


        </Box>
    )
}