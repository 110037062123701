
/**
 * Comment
 */

import { atom } from "jotai";
import { store } from "./atom.service";
import { User } from "./auth.service";
import axiosService from "./axios.service";
import { convertDataToUser } from "./user.service";



export const commentsAtom = atom<Record<string, CommentPayload>>({});

store.set(commentsAtom, {});

export function convertDataToComment(data: any): CommentPayload {
    const payload: CommentPayload = {
        _id: data._id,
        author: convertDataToUser(data.author),
        text: data.text,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt)
    }

    const mergedPayload = payload;

    store.set(commentsAtom, (comments) => ({
        ...comments,
        [data._id]: mergedPayload
    }));

    return mergedPayload;
}

export interface CommentPayload {
    _id: string;
    author: User;
    text: string;
    createdAt: Date;
    updatedAt: Date;
}


export const createComment = async (hackathonId: string, ideaId: string, text: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/comments/new`, { text });
    return convertDataToComment(data);
}
