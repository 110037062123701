import React from 'react';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.scss';

import theme from './theme';
import { Provider } from 'jotai';
import { store } from './services/atom.service';
import { PrivateRoutes } from './utils/PrivateRoutes';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PageNotFound } from './utils/PageNotFound';
import AppHeader from './components/AppHeader/AppHeader';
import Home from './pages/Home';
import { Login } from './pages/Login';
import { Privacy } from './pages/Privacy';
import { CookieBanner } from './components/CookieBanner/CookieBanner';
import { NewHackathon } from './pages/HackathonPage/NewHackathon';
import { HackathonPage } from './pages/HackathonPage/HackathonPage';
import { EditHackathon } from './pages/HackathonPage/EditHackathon';
import { FullIdeaPage } from './pages/IdeaPage/FullIdeaPage';
import { OrganizerPage } from './pages/HackathonPage/OrganizerPage';
import { JudgePage } from './pages/HackathonPage/JudgePage';
import { AppFooter } from './components/AppFooter/AppFooter';
import { VotePage } from './pages/HackathonPage/VotePage';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Stack from '@mui/material/Stack/Stack';
import Box from '@mui/material/Box/Box';
import { HowToRoutes } from './pages/HowTo/HowTo';
import { Presenters } from './pages/HackathonPage/Presenters';

export default function App() {

    React.useEffect(() => {

        // scroll percentage is used to determine how far to offset the background image
        const handleScroll = () => {
            const aspectRatio = 2880 / 1920;
            const scroll = window.scrollY;
            const percentage = (scroll / document.documentElement.scrollHeight);
            const imgHeight = window.innerWidth * aspectRatio;
            const scrollableDistance = imgHeight - window.innerHeight;
            document.body.style.setProperty('background-position-y', `${-percentage * scrollableDistance}px`);
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        }
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Stack className="App" spacing={1}>
                            <AppHeader />
                            <Box sx={{ minHeight: 'calc(100vh - 197px)' }}>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/privacy" element={<Privacy />} />

                                    <Route path="/h/:id" element={<HackathonPage />} />
                                    <Route element={<PrivateRoutes />}>
                                        <Route path="/new" element={<NewHackathon />} />
                                        <Route path="/h/:id/organizer" element={<OrganizerPage />} />
                                        <Route path="/h/:id/judge" element={<JudgePage />} />
                                        <Route path="/h/:id/vote" element={<VotePage />} />
                                        <Route path="/h/:id/edit" element={<EditHackathon />} />
                                        <Route path="/h/:id/idea/:ideaId" element={<FullIdeaPage />} />
                                        <Route path="/h/:id/presenters" element={<Presenters />} />
                                    </Route>
                                    {HowToRoutes}
                                    <Route path="*" element={<PageNotFound />} />
                                </Routes>
                            </Box>
                            <AppFooter />
                            <CookieBanner />
                        </Stack>
                    </BrowserRouter>
                </Provider>
            </ThemeProvider >
        </LocalizationProvider>
    )
}