import React, { useMemo } from 'react';
import { User } from '../../services/auth.service';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import EmojiPicker, { Emoji } from 'emoji-picker-react';

import './Reactions.scss';
import Stack from '@mui/material/Stack/Stack';
import IconButton from '@mui/material/IconButton/IconButton';
import Popover from '@mui/material/Popover/Popover';
export const Reactions = ({
    user,
    reactions,
    onAddReaction,
    onRemoveReaction
}: {
    user: User,
    reactions: Array<{
        user: User;
        emoji: string;
    }>;
    onAddReaction: (emoji: string) => void;
    onRemoveReaction: (emoji: string) => void;
}) => {

    const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);

    const emojis = useMemo(() => {
        const order: string[] = [];
        const _emojis = reactions.reduce((acc, reaction) => {
            if (!acc[reaction.emoji]) {
                acc[reaction.emoji] = []
            }
            if (!acc[reaction.emoji].some(u => u._id === reaction.user._id)) {
                acc[reaction.emoji].push(reaction.user);
            }
            if (!order.includes(reaction.emoji)) {
                order.push(reaction.emoji);
            }
            return acc;
        }, {} as Record<string, Array<User>>);

        return order.map(emoji => ({ emoji, users: _emojis[emoji] }));
    }, [reactions]);

    const ref = React.useRef<HTMLButtonElement | null>(null);
    return (
        <Stack direction={'row'} className="reactions" spacing={0.5}>
            {
                emojis.map(emoji => (
                    <button className={`emoji ${emoji.users.some(u => u._id === user._id) ? 'selected' : ''}`} key={emoji.emoji} onClick={() => {
                        if (emoji.users.some(u => u._id === user._id)) {
                            onRemoveReaction(emoji.emoji);
                        } else {
                            onAddReaction(emoji.emoji);
                        }
                    }}>
                        <Emoji size={20} unified={emoji.emoji} /> <span className="num">{emoji.users.length}</span>
                        <div className="tooltip">
                            {
                                emoji.users.map(u => (
                                    <div key={u._id}>{u.username}</div>
                                ))
                            }
                        </div>
                    </button>
                ))
            }

            <IconButton ref={ref} size={'small'} title={'Add an emoji!'} onClick={() => {
                setShowEmojiPicker(!showEmojiPicker);
            }}>
                <InsertEmoticonIcon />
            </IconButton>
            <Popover
                open={showEmojiPicker}
                anchorEl={ref.current}
                anchorPosition={{ top: 0, left: 0 }}
                onClose={() => {
                    setShowEmojiPicker(false);
                }}
            >
                <EmojiPicker
                    onEmojiClick={(emoji) => {
                        setShowEmojiPicker(false);
                        onAddReaction(emoji.unified);
                    }}
                />
            </Popover>
        </Stack>
    )
}

export default Reactions;