import React from 'react';
import { CommentPayload } from '../../services/comment.service';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Markdown } from '../Markdown/Markdown';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Stack from '@mui/material/Stack/Stack';

dayjs.extend(relativeTime);
export const Comment = (comment: CommentPayload) => {
    const postTime = dayjs(comment.createdAt).fromNow();
    const updatedTime = dayjs(comment.updatedAt).fromNow();
    return (
        <Card>
            <CardContent>
                <Stack spacing={2} direction="column" >
                    <Stack direction="row" spacing={2}>
                        <div style={{ color: 'gray', fontSize: '0.8em' }}>{postTime} by <b>{comment.author.username}</b></div>
                        <div style={{ color: 'gray', fontSize: '0.8em' }}>{updatedTime !== postTime && (`(Last modified ${updatedTime})`)}</div>
                    </Stack>
                    <Markdown markdown={comment.text} />
                </Stack>
            </CardContent>
        </Card>
    )
}