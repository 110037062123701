
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import { disableVotingPeriod, enableVotingPeriod, endVotingPeriod } from '../../services/hackathon.service';
import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import PeopleIcon from '@mui/icons-material/People';
import PollIcon from '@mui/icons-material/Poll';
import { useAuth } from '../../services/useAuth';
import { PageContainer } from '../../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import { IdeaPayload } from '../../services/idea.service';
import { aggregateVotes } from '../../services/vote.service';
export const VotePage = () => {
    const { id } = useParams<{ id: string }>();

    const { hackathon, reload } = useHackathon(id);

    const [activeTab, setActiveTab] = React.useState(0);

    const ideasMap = useMemo(() => {
        if (!hackathon) {
            return new Map<string, IdeaPayload>();
        }
        const ideasMap = new Map<string, IdeaPayload>();
        hackathon?.ideas.forEach(idea => {
            ideasMap.set(idea._id, idea);
        });
        return ideasMap;
    }, [hackathon])
    const categories: Array<{
        category: string,
        results: Array<{
            ideaId: string,
            judgeVoteAverage: number,
            participantVoteAverage: number
        }>
    }> = useMemo(() => {
        if (!hackathon) {
            return [];
        }

        return hackathon.categories.map((category, index) => {
            const results = hackathon.results && hackathon.results.find(r => r.category === category);
            if (!results) {
                return {
                    category,
                    results: []
                }
            }

            return {
                category,
                results: [...results.ideas].sort((a, b) => {
                    if (b.judgeVoteAverage === a.judgeVoteAverage && hackathon.participantScores) {
                        return b.participantVoteAverage - a.participantVoteAverage
                    }

                    return b.judgeVoteAverage - a.judgeVoteAverage
                })
            }
        });
    }, [hackathon])

    const { user } = useAuth();
    const isJudgeOrOrganizer = useMemo(() => {
        return user && hackathon && (hackathon.judges.some(j => j.email === user.email) || hackathon.organizers.some(o => o.email === user.email));
    }, [hackathon, user])

    if (!hackathon) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>No hackathon found</h1>
            </Stack>
        </PageContainer>
    }

    if (!isJudgeOrOrganizer) {
        return <PageContainer>
            <Alert severity="error">You are not authorized to view this page</Alert>
        </PageContainer>
    }

    if (!hackathon.categories || hackathon.categories.length === 0) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>No categories to vote on</h1>
            </Stack>
        </PageContainer>
    }

    return (
        <PageContainer>
            <AppBreadcrumbs crumbs={[
                {
                    type: PageType.Home,
                    link: '/',
                    name: 'Home'
                },
                {
                    type: PageType.Hackathon,
                    link: `/h/${hackathon.slug}`,
                    name: hackathon.name
                },
                {
                    type: PageType.Results,
                    name: 'Voting Results'
                }
            ]} />
            <Stack spacing={2}>
                <h1>{hackathon.name} Voting</h1>
                <Typography><b>{hackathon.ideas.length} ideas</b>, <b>{hackathon.categories.length} categories</b></Typography>
                {
                    hackathon.votingPeriodOn && <Alert severity="info">Voting period is open</Alert>
                }
                <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={() => {
                        // end voting period
                        endVotingPeriod(hackathon._id).then(() => setActiveTab(0));
                    }}>End Voting</Button>
                    <Button variant="outlined" onClick={() => {
                        enableVotingPeriod(hackathon._id).then(() => setActiveTab(0));
                    }}>Open Voting</Button>
                    <Button variant="outlined" onClick={() => {
                        disableVotingPeriod(hackathon._id).then(() => setActiveTab(0));
                    }}>Close Voting</Button>
                    <Button variant="outlined" onClick={() => {
                        aggregateVotes(hackathon._id).then(() => setActiveTab(0));
                    }}>Refresh Results</Button>
                </Stack>
                <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                    {
                        hackathon.categories.map((category, index) => (
                            <Tab key={category} value={index} label={category} />
                        ))
                    }
                </Tabs>
                {
                    categories.map(({ category, results }, index) => {


                        return (
                            <TabPanel key={category} value={activeTab} index={index}>
                                <Stack spacing={2}>
                                    {
                                        !results && <Typography>No results</Typography>
                                    }
                                    {
                                        results && (
                                            <ol>
                                                <Stack spacing={2}>
                                                    {
                                                        results.map((ideaResult, index) => {
                                                            const idea = ideasMap.get(ideaResult.ideaId);
                                                            if (!idea) {
                                                                return null;
                                                            }
                                                            return (
                                                                <li style={{ fontSize: '1.25em' }} key={ideaResult.ideaId}>
                                                                    <Stack direction={'column'} spacing={1}>
                                                                        <Stack direction='row' spacing={2} alignItems={'center'}>
                                                                            <Typography variant={'h6'}>
                                                                                <Link to={`/h/${hackathon.slug}/idea/${idea._id}`}>{idea.title}</Link>
                                                                            </Typography>
                                                                            <Typography>
                                                                                <b>Judges:</b> {ideaResult.judgeVoteAverage.toFixed(2)}
                                                                            </Typography>
                                                                            {
                                                                                hackathon.participantScores && <Typography>
                                                                                    <b>Participants:</b> {ideaResult.participantVoteAverage.toFixed(2)}
                                                                                </Typography>
                                                                            }
                                                                        </Stack>

                                                                        <Typography><b>Team:</b> {idea.members.map(m => m.username).join(', ')}</Typography>
                                                                    </Stack>
                                                                </li>
                                                            )
                                                        }).filter(Boolean)
                                                    }
                                                </Stack>
                                            </ol>
                                        )
                                    }
                                </Stack>
                            </TabPanel>
                        )
                    })
                }
            </Stack>
        </PageContainer>
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = ({
    children,
    value,
    index,
    ...others
}: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...others}
        >
            {value === index && (
                <Stack spacing={2}>
                    {children}
                </Stack>
            )}
        </div>
    );
}