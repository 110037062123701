import React from 'react';
import { HackathonPayload } from '../../services/hackathon.service';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';

export const JudgeVoteBadge = ({
    hackathon,
    judgeId,
    score
}: {
    hackathon: HackathonPayload,
    judgeId: string,
    score: number
}) => {

    const judge = hackathon.judges.find(j => j._id === judgeId);


    return (
        <Tooltip title={judge?.username || 'Anon'}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 0.5,
                py: 0.25,
                border: '1px solid #ccc',
                borderRadius: 4,
                margin: 0.25,
                width: 24,
                height: 24,
                textAlign: 'center'
            }}>

                <Typography fontSize={12}>{score}</Typography>
            </Box>

        </Tooltip>
    )
}