import React from 'react';

import { PageContainer } from '../../components/PageContainer';
import List from '@mui/material/List/List';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack/Stack';
import { HowToArticles } from './HowTo';
import ListItem from '@mui/material/ListItem/ListItem';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';

export const HowToIndex = () => {

    const navigate = useNavigate();

    return (
        <PageContainer>
            <Stack direction="column" spacing={2}>
                <AppBreadcrumbs crumbs={[
                    {
                        type: PageType.Home,
                        link: '/',
                        name: 'Home'
                    },
                    {
                        type: PageType.HowTo,
                        name: 'How To'
                    }
                ]} />
                <h1>Hack-in-a-Box How To Articles</h1>
                <List>
                    {
                        HowToArticles.map((article, index) => {
                            return (
                                <ListItem key={index}>
                                    <NavLink to={`/how/to/${article.link}`}>{article.title}</NavLink>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Stack>

        </PageContainer>
    )
}