
import React, { useMemo, useState } from 'react';
import { useAuth } from '../../services/useAuth';
import { login, logout } from '../../services/auth.service';
import './AppHeader.scss';
import { Logo } from '../logo/icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { atom } from 'jotai';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import { useHackathon } from '../../hooks/useHackathon';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import IconButton from '@mui/material/IconButton/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer/Drawer';
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader/ListSubheader';
import Collapse from '@mui/material/Collapse/Collapse';


import HelpCenterIcon from '@mui/icons-material/HelpCenter';


export const NavMenuOpened = atom(false);


export default function AppHeader() {
    const desktopMode = useDesktopMode();

    const { user, loading } = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setDrawerOpen(open);
    }


    const location = useLocation();

    const hackathonId = useMemo(() => {
        if (location.pathname.startsWith('/h/')) {
            return location.pathname.split('/')[2];
        }
        return '';
    }, [location.pathname])

    const { hackathon } = useHackathon(hackathonId);

    const isOrganizer = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return hackathon.organizers.some(o => o.email === user.email);
    }, [user, hackathon])

    const isJudge = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return hackathon.judges.some(o => o.email === user.email);
    }, [user, hackathon])
    return (
        <div className="app-header no-print-no-display" id="back-to-top-anchor">
            <Container >
                <Stack direction="row" alignItems="center" spacing={2} className="header-container">
                    {
                        hackathon && hackathon.ideas.length > 2 && <IconButton title={'Menu'} onClick={() => {
                            toggleDrawer(true)
                        }}><MenuIcon /></IconButton>
                    }
                    <Link to={'/'} className='main-link'>
                        <Logo size="small" />
                        {desktopMode && <h1>Hack in a Box</h1>}
                    </Link>
                    <div className="spacer"></div>
                    <IconButton title={'Help'} color={'primary'} onClick={() => {
                        window.open('/how/to', '_blank');
                    }}>
                        <HelpCenterIcon />
                    </IconButton>
                    {
                        desktopMode && user && <>
                            <Button variant={'text'} onClick={() => {
                                navigate('/new');
                            }} color={'primary'}>New Hackathon</Button>
                        </>
                    }
                    {
                        loading && <CircularProgress />
                    }
                    {
                        !loading && !user && <Button onClick={(e) => { login() }}>Login/Register</Button>
                    }
                    {
                        (!loading && !!user) && <>
                            <Button id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>{user.username}</Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </Menu>
                        </>
                    }
                </Stack>
            </Container>
            <Drawer open={drawerOpen} onClose={() => {
                toggleDrawer(false)
            }}>
                <List>
                    {
                        hackathon && <ListItemButton onClick={() => {
                            navigate(`/h/${hackathon.slug}`);
                            toggleDrawer(false);
                        }}>{hackathon.name}</ListItemButton>
                    }
                    {
                        hackathon && (isOrganizer || isJudge) && <ListItemButton onClick={() => {
                            navigate(`/h/${hackathon.slug}/judge`);
                        }}>Judging</ListItemButton>
                    }
                    <ListSubheader>Ideas</ListSubheader>

                    {
                        hackathon && hackathon.ideas.map(idea => {
                            return <ListItemButton sx={{ maxWidth: 350, pl: 4 }} key={idea._id} onClick={() => {
                                navigate(`/h/${hackathon.slug}/idea/${idea._id}`);
                                toggleDrawer(false);
                            }}>{idea.title}</ListItemButton>
                        })
                    }

                </List>
            </Drawer>
        </div>
    )
}