import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

export default theme;