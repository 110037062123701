import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import './Markdown.scss';

const renderer = new marked.Renderer();

export const Markdown = ({
    markdown
}: {
    markdown: string;
}) => {
    const html = React.useMemo(() => {
        return DOMPurify.sanitize(
            marked(markdown, { mangle: false, headerIds: false, renderer })
        );
    }, [markdown]);

    return (
        <div className="marked" dangerouslySetInnerHTML={{ __html: html }} />
    )
}