import React, { lazy, useEffect, useState } from 'react';
import { useVote } from '../../hooks/useVote';
import { HackathonPayload } from '../../services/hackathon.service';
import { useAuth } from '../../services/useAuth';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Typography from '@mui/material/Typography/Typography';
import Stack from '@mui/material/Stack/Stack';
import Slider from '@mui/material/Slider/Slider';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import Alert from '@mui/material/Alert/Alert';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { IdeaPayload } from '../../services/idea.service';
import { submitVote } from '../../services/vote.service';

export const JudgeVoteCard = ({
    hackathon,
    idea,
    onSubmit
}: {
    hackathon: HackathonPayload,
    idea: IdeaPayload,
    onSubmit?: () => void
}) => {

    let { vote, reload } = useVote(hackathon._id, idea._id)
    const { user } = useAuth();

    const isJudge = user && hackathon.judges.some(j => j.email === user.email);

    const [notes, setNotes] = useState<string[]>([]);
    const [scores, setScores] = useState<number[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [showSubmitMessage, setShowSubmitMessage] = useState(false);

    const desktopMode = useDesktopMode();
    useEffect(() => {
        if (vote) {
            setNotes(vote.notes || []);
            setScores(vote.scores || []);
        }
    }, [vote])

    if (!hackathon || !hackathon.categories || hackathon.categories.length === 0) {
        return <Card>
            <CardContent>
                <Typography variant="h6">No categories to vote on</Typography>
            </CardContent>
        </Card>
    }

    if (!vote || !vote.scores) {
        if (!vote) {
            vote = {
                _id: '0',
                userId: '0',
                ideaId: idea._id,
                scores: new Array(hackathon.categories.length).fill(0),
                notes: new Array(hackathon.categories.length).fill(''),
            }
        }
        vote.scores = new Array(hackathon.categories.length).fill(0);
        vote.notes = new Array(hackathon.categories.length).fill('');
    }

    return (
        <Card>
            <CardContent sx={{ overflow: 'auto' }}>
                <Stack direction="row" flexWrap={"wrap"} spacing={0}>
                    {
                        (<>
                            {
                                hackathon.categories.map((category, index) => {
                                    const visible = hackathon.categoryMeta[index]?.judgeOnly ? isJudge : true;
                                    if (!visible) return null;
                                    return (
                                        <Stack key={category} direction="column" margin={2} width={desktopMode ? 320 : "100%"} spacing={0}>
                                            <Typography sx={{ minWidth: 100 }}>{category}: {!vote.scores[index] ? 'Abstain' : vote.scores[index]}</Typography>
                                            {/* <Stack direction="row" flexWrap={'wrap'} spacing={0} alignItems={'flex-start'} justifyContent={'flex-start'}>
                                            {
                                                scores.map(score => (
                                                    <Button
                                                        key={score}
                                                        className={vote?.scores[index] === score ? 'selected' : ''}

                                                        variant={vote?.scores[index] === score ? 'contained' : 'outlined'}
                                                        sx={{
                                                            px: 1,
                                                            minWidth: 42,
                                                            margin: "4px"
                                                        }}

                                                        onClick={() => {
                                                            if (vote.scores[index] !== score) {
                                                                submitVote(hackathon._id, idea._id, [...vote.scores.slice(0, index), score, ...vote.scores.slice(index + 1)])
                                                            }
                                                        }}>{score}</Button>
                                                ))
                                            }
                                            <Button
                                                sx={{
                                                    px: 1,
                                                    minWidth: 42,
                                                    margin: "4px"
                                                }}
                                                variant={!vote?.scores[index] ? 'contained' : 'outlined'}
                                                onClick={() => {
                                                    submitVote(hackathon._id, idea._id, [...vote.scores.slice(0, index), 0, ...vote.scores.slice(index + 1)])
                                                }}>Abstain</Button>
                                        </Stack> */}
                                            <Stack direction="row">
                                                <Slider
                                                    value={vote.scores[index] ?? 0}
                                                    onChange={(e, val) => {
                                                        vote.scores[index] = val as number;
                                                        setScores([...vote.scores]);
                                                    }}
                                                    valueLabelDisplay="auto"
                                                    step={1}
                                                    marks
                                                    min={0}
                                                    max={10}
                                                />
                                            </Stack>
                                            <TextField
                                                label={<Typography>Notes (for yourself only)</Typography>}
                                                multiline={true}
                                                minRows={2}
                                                value={notes[index] || ''} onChange={(e) => {
                                                    notes[index] = e.target.value;
                                                    setNotes([...notes]);
                                                }} />
                                        </Stack>
                                    )
                                })
                            }
                        </>)
                    }
                </Stack>

                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        disabled={submitting}
                        variant="contained"
                        onClick={() => {
                            setSubmitting(true);
                            submitVote(hackathon._id, idea._id, vote.scores, notes).then(() => {
                                setSubmitting(false);
                                setShowSubmitMessage(true);
                                onSubmit && onSubmit();
                                setTimeout(() => {
                                    setShowSubmitMessage(false);
                                }, 3000)
                            }, (err) => {
                                console.error(err);
                                setSubmitting(false);
                            })
                        }}>Submit Vote</Button>
                    {
                        showSubmitMessage && <Alert severity="success">Thank you for voting!</Alert>
                    }
                    {
                        submitting && <CircularProgress />
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}