import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import Fade from '@mui/material/Fade/Fade';
import Paper from '@mui/material/Paper/Paper';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import React from 'react';

export const CookieBanner = () => {
    const [showBanner, setShowBanner] = React.useState(false);

    React.useEffect(() => {
        const cookie = localStorage.getItem('cookie');
        if (!cookie) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookie', 'accepted');
        setShowBanner(false);
    }

    const closeBanner = () => {
        localStorage.setItem('cookie', 'rejected');
        setShowBanner(false);
    }

    return (
        <Fade appear={false} in={showBanner}>
            <Paper
                role="dialog"
                aria-modal="false"
                aria-label="Cookie banner"
                square
                variant="outlined"
                tabIndex={-1}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    m: 0,
                    p: 2,
                    borderWidth: 0,
                    borderTopWidth: 1,
                    zIndex: 1051
                }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-between"
                    gap={2}
                >
                    <Box
                        sx={{
                            flexShrink: 1,
                            alignSelf: { xs: 'flex-start', sm: 'center' },
                        }}
                    >
                        <Typography fontWeight="bold">This website uses cookies</Typography>
                        <Typography variant="body2">
                            We use cookies to improve user experience, and analyze website traffic.
                        </Typography>
                    </Box>
                    <Stack
                        gap={2}
                        direction={{
                            xs: 'row-reverse',
                            sm: 'row',
                        }}
                        sx={{
                            flexShrink: 0,
                            alignSelf: { xs: 'flex-end', sm: 'center' },
                        }}
                    >
                        <Button size="small" onClick={acceptCookies} variant="contained">
                            Allow all
                        </Button>
                        <Button size="small" onClick={closeBanner}>
                            Reject all
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Fade>
    )
}