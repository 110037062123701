import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
export const CategorySortMenu = ({
    selected,
    onSelect
}: {
    selected?: string,
    onSelect: (type: string) => void
}) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-label="Sort"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    py: 0,
                    px: 0,
                    width: '32px',
                    minWidth: '32px'
                }}
            >
                {!selected && <MoreVertIcon />}
                {
                    selected && ('')
                }
                {selected === 'judge' && <>J<SortIcon /></>}
                {selected === 'participant' && <>P<SortIcon /></>}
            </Button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    onSelect('judge');
                    handleClose();
                }}>
                    Judge Votes
                </MenuItem>
                <MenuItem onClick={() => {
                    onSelect('participant');
                    handleClose();
                }}>
                    Participant Votes
                </MenuItem>
            </Menu>
        </>
    )
}