import React, { lazy, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import Alert from '@mui/material/Alert/Alert';
import { createIdea, IdeaOptions } from '../../services/idea.service';

const Editor = lazy(() => import('../Editor/Editor'));

export const NewIdea = ({
    hackathonId,
    template,
    onCreate
}: {
    hackathonId: string,
    template?: string,
    onCreate: (ideaId: string) => void
}) => {

    const [idea, setIdea] = useState<IdeaOptions>({
        title: '',
        description: template ?? ''
    })

    const [error, setError] = useState('');

    return (
        <form
            id="new-idea-form"
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!idea.title || !idea.description) {
                    setError('Please fill in all fields');
                    return;
                }

                createIdea(hackathonId, idea).then((data) => {
                    // window.location.reload();
                    onCreate(data._id);

                    setIdea({
                        title: '',
                        description: template ?? ''
                    });
                }, (err) => {
                    setError(err.response?.data.message || 'An error occurred');
                });

            }}>
            <Stack spacing={2} direction={'column'}>
                <h2>Submit a new idea</h2>

                <TextField label="Title" value={idea.title} onChange={(e) => setIdea({ ...idea, title: e.target.value })} />
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Editor toolbar={true} value={idea.description} onChange={(value) => setIdea({ ...idea, description: value })} />
                </React.Suspense>
                <Stack direction={'row'} spacing={2}>
                    <Button type="submit" variant={'contained'}>Submit</Button>
                    {
                        error && <Alert severity='error'>{error}</Alert>
                    }
                </Stack>


            </Stack>
        </form>
    )

}
