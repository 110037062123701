import React, { useState } from 'react';

import { joinHackathon } from '../../services/hackathon.service';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';

export const JoinHackathon = ({
    hackathonId,
    needsInviteCode
}: {
    hackathonId: string,
    needsInviteCode: boolean
}) => {

    const codeQP = new URLSearchParams(window.location.search).get('code');

    const [inviteCode, setInviteCode] = useState<string>(codeQP || '');
    const [error, setError] = useState<string>('');
    const [joining, setJoining] = useState(false);

    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    {
                        needsInviteCode && (
                            <>
                                <Alert severity='info'>You need an invite code to join</Alert>
                                <TextField label="Invite code" value={inviteCode} onChange={(e) => {
                                    setInviteCode(e.target.value);
                                    setError('')
                                }} />
                            </>
                        )
                    }

                    {
                        error && <Alert severity='error'>{error}</Alert>
                    }
                    <Stack direction="row" spacing={2}>

                        <Button onClick={() => {
                            if (joining) {
                                return false;
                            }
                            setJoining(true);
                            joinHackathon(hackathonId, inviteCode).then(() => {
                                window.location.reload();
                                setJoining(false);
                            }, (err) => {
                                setError(err.response?.data.message || 'An error occurred');
                                setJoining(false);
                            });
                        }} variant="contained">Join</Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}