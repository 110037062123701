import React, { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { ideasAtom, getIdea } from '../services/idea.service';

export const useIdea = (hackathonId: string | undefined, id: string | undefined) => {
    if (!id || !hackathonId) {
        return {
            idea: null,
            loading: false,
            error: null
        }
    }
    const [ideas] = useAtom(ideasAtom)

    const idea = useMemo(() => {
        if (ideas && id && ideas[id]) {
            return ideas[id];
        }
        return null;
    }, [ideas, id])
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<AxiosError | null>(null);

    const [reloadRequest, setReloadRequest] = React.useState<number>(0);

    const reload = React.useCallback(() => {
        setReloadRequest(reloadRequest + 1);
    }, [reloadRequest]);

    React.useEffect(() => {
        getIdea(hackathonId, id).then(idea => {
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });
    }, [id, reloadRequest, hackathonId]);

    return { idea, loading, error, reload };
}