import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth } from '../services/useAuth';
import { login } from '../services/auth.service';
export const PrivateRoutes = () => {

    const { user, loading } = useAuth()

    if (loading) {
        return <></>
    }

    if (!user) {
        login();
        return <></>
    }

    return (
        <Outlet />
    )
}