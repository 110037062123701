import React from 'react';
import { HackathonPayload } from '../../services/hackathon.service';
import { Link } from 'react-router-dom';
import { Markdown } from '../../components/Markdown/Markdown';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import Alert from '@mui/material/Alert/Alert';

export const HackathonSummary = ({
    hackathon
}: {
    hackathon: HackathonPayload
}) => {
    return <Box>
        <Stack spacing={2} padding={2}>
            <h3><Link to={`/h/${hackathon.slug}`}>{hackathon.name}</Link></h3>
            <Typography>Organized by {hackathon.organizers.map(u => u.username).join(', ')}</Typography>
            <Markdown markdown={hackathon.description} />
            <Typography>Dates: {hackathon.startDate?.toLocaleDateString()} - {hackathon.endDate?.toLocaleDateString()}</Typography>
            {
                hackathon.ideaSubmissionDeadline && <Typography>Idea submission deadline: {hackathon.ideaSubmissionDeadline.toLocaleDateString()}</Typography>
            }
            {
                hackathon.votingPeriodOn && <Alert severity="info">Voting is live!</Alert>
            }
        </Stack>
    </Box>
}