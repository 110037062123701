import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAuth } from '../../services/useAuth';
import { disableVotingPeriod, enableVotingPeriod, setCurrentlyPresentingProjectId } from '../../services/hackathon.service';
import { useHackathon } from '../../hooks/useHackathon';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button/Button';
import { HackathonPoller } from '../../services/statepoller.service';
import { IdeaPayload } from '../../services/idea.service';
import { Subscription } from 'rxjs';
import { usePresentation } from '../../hooks/usePresentation';

/**
 * LiveVotingBanner
 * 
 * This component will poll the server to check if voting is live, if it is, the user will receive a link to the current
 * active project page for voting. Alternatively, it will give a button that loads the voting widget directly on the page
 * via a bottom drawer.
 */

export const LiveVotingBanner = ({
    hackathonId,
    ideaId,
    onClickActiveIdea,
    onCurrentIdeaChange
}: {
    hackathonId: string,
    ideaId?: string,
    onClickActiveIdea?: (idea: IdeaPayload) => void,
    onCurrentIdeaChange?: (idea: IdeaPayload | null) => void
}) => {
    const { user } = useAuth();

    const { hackathon, reload } = useHackathon(hackathonId);

    const isOrganizer = useMemo(() => {
        return !!user && hackathon && hackathon.organizers.some(o => o.email === user.email);
    }, [hackathon, user])

    const {
        currentlyPresentingProjectId,
        votingPeriodOn
    } = usePresentation(hackathonId);


    const activeIdea = useMemo(() => {
        return votingPeriodOn && hackathon?.ideas.find(idea => idea._id === currentlyPresentingProjectId);
    }, [hackathon, currentlyPresentingProjectId, votingPeriodOn])

    const isCurrentIdea = useMemo(() => {
        return hackathon && activeIdea && activeIdea._id === ideaId
    }, [hackathon, activeIdea, ideaId])

    const navigate = useNavigate();

    if (!hackathon) {
        return null;
    }
    return (
        <>
            {
                (activeIdea || isOrganizer) && (
                    <Alert severity='info' sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 2
                    }} action={
                        <>
                            {isOrganizer && (
                                votingPeriodOn ? (
                                    <Button onClick={() => {
                                        // open voting widget
                                        // enable voting
                                        disableVotingPeriod(hackathon._id).then(() => {
                                            reload();
                                        }).catch((err: AxiosError) => {
                                            console.error(err);
                                        })
                                    }}>Close Voting</Button>
                                ) : (
                                    <Button onClick={
                                        () => {
                                            // open voting widget
                                            // enable voting
                                            enableVotingPeriod(hackathon._id).then(() => {
                                                reload();
                                            }).catch((err: AxiosError) => {
                                                console.error(err);
                                            })
                                        }}>Open Voting</Button>
                                )
                            )}

                            {
                                isOrganizer && votingPeriodOn && (
                                    ideaId && !isCurrentIdea && (
                                        <Button onClick={() => {
                                            // set the currently presenting project to the ideaId
                                            setCurrentlyPresentingProjectId(hackathon._id, ideaId).catch((err: AxiosError) => {
                                                console.error(err);
                                            })
                                        }}>Set currently presenting</Button>
                                    )
                                )
                            }

                        </>
                    }>
                        {
                            votingPeriodOn && activeIdea && (
                                <>
                                    Voting is on for <Button sx={{ py: 0 }} onClick={() => {
                                        if (onClickActiveIdea) {
                                            onClickActiveIdea(activeIdea);
                                        } else {
                                            navigate(`/h/${hackathon.slug}/idea/${activeIdea._id}#voting`)
                                        }
                                    }}>{activeIdea.title}</Button>
                                </>
                            )
                        }

                        {
                            isOrganizer && !activeIdea && (
                                <>
                                    Voting is {votingPeriodOn ? 'live' : 'off'}
                                </>
                            )
                        }
                    </Alert >
                )
            }

        </>
    )
}