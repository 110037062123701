import React, { useEffect, useRef } from 'react';

import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    ChangeCodeMirrorLanguage,
    codeBlockPlugin,
    codeMirrorPlugin,
    CodeToggle,
    ConditionalContents,
    headingsPlugin,
    InsertCodeBlock,
    InsertSandpack,
    InsertTable,
    listsPlugin,
    ListsToggle,
    markdownShortcutPlugin,
    MDXEditor,
    MDXEditorMethods,
    quotePlugin,
    ShowSandpackInfo,
    tablePlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

import './Editor.scss';

const Editor = ({
    value,
    toolbar,
    short,
    onChange
}: {
    value: string;
    short?: boolean;
    toolbar?: boolean;
    onChange: (value: string) => void;
}) => {
    const ref = React.useRef<MDXEditorMethods>(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.setMarkdown(value);
        }
    }, [value, ref])

    const plugins = [
        codeBlockPlugin({ defaultCodeBlockLanguage: 'ts' }),
        codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS', ts: 'TypeScript', md: 'Markdown', html: 'HTML', py: 'Python', rb: 'Ruby' } }),
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        tablePlugin(),
        markdownShortcutPlugin()
    ]
    if (toolbar) {
        plugins.push(toolbarPlugin({
            toolbarContents: () =>
                <>
                    <UndoRedo />
                    <BlockTypeSelect />
                    <BoldItalicUnderlineToggles />
                    <CodeToggle />
                    <InsertTable />
                    <ListsToggle />
                    <ConditionalContents
                        options={[
                            { when: (editor) => editor?.editorType === 'codeblock', contents: () => <ChangeCodeMirrorLanguage /> },
                            { when: (editor) => editor?.editorType === 'sandpack', contents: () => <ShowSandpackInfo /> },
                            {
                                fallback: () => (<>
                                    <InsertCodeBlock />
                                    <InsertSandpack />
                                </>)
                            }
                        ]}
                    />
                </>

        }),)
    }
    return (
        <div className="editor">

            <MDXEditor className={short ? 'editor-short' : ''} ref={ref} markdown={value} onChange={(markdown) => {

                onChange(markdown)
            }}
                plugins={plugins}
            />

        </div>
    )
}

export default Editor;

// export const Editor = ({
//     value,
//     onChange
// }: {
//     value: string;
//     onChange: (value: string) => void;
// }) => {

//     const rteRef = useRef<RichTextEditorRef>(null);

//     useEffect(() => {
//         if (rteRef.current && !value) {
//             rteRef.current.editor?.commands.clearContent();
//         }
//     }, [value])

//     return (
//         <RichTextEditor
//             ref={rteRef}
//             extensions={[StarterKit]} // Or any Tiptap extensions you wish!
//             content={value} // Initial content for the editor
//             // Optionally include `renderControls` for a menu-bar atop the editor:
//             renderControls={() => (
//                 <MenuControlsContainer>
//                     <MenuSelectHeading />
//                     <MenuDivider />
//                     <MenuButtonBold />
//                     <MenuButtonItalic />
//                     {/* Add more controls of your choosing here */}
//                     <MenuButtonBulletedList />
//                     <MenuButtonCodeBlock />

//                 </MenuControlsContainer>
//             )}
//         />

//     )
// }