import React, { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { HackathonPoller } from '../services/statepoller.service';


export const usePresentation = (hackathonId: string | undefined) => {


    const subscriptionRef = useRef<Subscription>();

    const [data, setData] = React.useState<{
        currentlyPresentingProjectId: string,
        votingPeriodOn: boolean,
        presentationOrder: string[],
        presented: string[]
    }>({
        currentlyPresentingProjectId: '',
        votingPeriodOn: false,
        presentationOrder: [],
        presented: []
    });

    useEffect(() => {
        if (!hackathonId) return;

        if (!subscriptionRef.current || subscriptionRef.current.closed) {
            subscriptionRef.current = HackathonPoller.subscribe(hackathonId, (data) => {
                if (data) {
                    setData(data);
                }
            })
        }

        return () => {
            subscriptionRef.current?.unsubscribe();
        }
    }, [hackathonId])

    return data;
}