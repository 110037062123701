import { getCurrentlyPresentingProjectId } from "./hackathon.service";
import { BehaviorSubject, Observer, Subject } from "rxjs";
let currentHackathonId: string = '';
let timeout: NodeJS.Timeout | null = null;
let delay = 120000;

const subject = new BehaviorSubject<{
    currentlyPresentingProjectId: string,
    votingPeriodOn: boolean,
    presentationOrder: string[],
    presented: string[]
} | null>(null);

export const HackathonPoller = {
    subscribe: (hackathonId: string, next: (data: {
        currentlyPresentingProjectId: string,
        votingPeriodOn: boolean,
        presentationOrder: string[],
        presented: string[]
    }) => void) => {
        if (!hackathonId) return;
        HackathonPoller.setHackathonId(hackathonId);
        return subject.subscribe((value) => {
            if (!value) return;
            next(value);
        })
    },
    setHackathonId: (hackathonId: string) => {
        if (currentHackathonId !== hackathonId) {
            currentHackathonId = hackathonId;
            poll();
        }
    },
    maybeUpdate: (hackathonId: string, data: {
        currentlyPresentingProjectId: string,
        votingPeriodOn: boolean,
        presentationOrder: string[],
        presented: string[]
    }) => {
        if (currentHackathonId === hackathonId) {
            subject.next({
                currentlyPresentingProjectId: data.currentlyPresentingProjectId,
                votingPeriodOn: data.votingPeriodOn,
                presentationOrder: data.presentationOrder,
                presented: data.presented
            });
        }
    }
}

function poll() {
    if (timeout) clearTimeout(timeout);
    if (!currentHackathonId) return;
    getCurrentlyPresentingProjectId(currentHackathonId).then(({
        currentlyPresentingProjectId,
        votingPeriodOn,
        presentationOrder,
        presented
    }) => {
        if (votingPeriodOn) {
            delay = 20000;
        } else {
            delay = 120000;
        }
        const payload = {
            currentlyPresentingProjectId,
            votingPeriodOn,
            presentationOrder,
            presented
        };

        subject.next(payload)
    }).finally(() => {
        timeout = setTimeout(() => poll(), delay);
    })
}
