import React, { lazy } from 'react';
import Stack from '@mui/material/Stack';
import { createComment } from '../../services/comment.service';
import Button from '@mui/material/Button/Button';
import Alert from '@mui/material/Alert/Alert';
const Editor = lazy(() => import('../Editor/Editor'));

export const NewComment = ({
    hackathonId,
    ideaId,
    submitted
}: {
    hackathonId: string,
    ideaId: string,
    submitted: () => void
}) => {
    const [comment, setComment] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');

    const submit = async () => {
        setLoading(true);
        createComment(hackathonId, ideaId, comment).then(() => {
            setLoading(false);
            setError('');
            submitted()
            setComment('');
        }, (err) => {
            console.error(err);
            setError(err.response?.data.message || 'An error occurred');
            setLoading(false);
        });
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            submit();

        }}>
            <Stack spacing={2} direction="column">
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Editor toolbar={true} value={comment} onChange={(value) => setComment(value)} />
                </React.Suspense>
                <Stack spacing={2} direction="row">
                    <Button type="submit" variant="contained" disabled={loading}>Submit Comment</Button>
                    {
                        error && <Alert severity='error'>{error}</Alert>
                    }
                </Stack>
            </Stack>
        </form>
    )
}