import React from 'react';

import { PageContainer } from '../../components/PageContainer';
import { Outlet, Route } from 'react-router-dom';
import { HowToIndex } from '.';
import Stack from '@mui/material/Stack/Stack';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import { Markdown } from '../../components/Markdown/Markdown';
import Box from '@mui/material/Box/Box';


/** Articles */
import submitIdea from './submit_idea.md';
import castVote from './cast_vote.md';
import facilitatePresentations from './facilitate_presentations.md';

export const HowTo = () => {

    return (
        <PageContainer>
            <Outlet />
        </PageContainer>
    )
}

export const HowToContainer = ({
    name,
    article
}: {
    name: string,
    article: string
}) => {
    return (
        <PageContainer>
            <Stack direction="column" spacing={2}>
                <AppBreadcrumbs crumbs={
                    [
                        {
                            type: PageType.Home,
                            link: '/',
                            name: 'Home'
                        },
                        {
                            type: PageType.HowTo,
                            link: '/how/to',
                            name: 'How To'
                        },
                        {
                            type: PageType.Article,
                            name: name
                        }
                    ]
                } />
                <Box>
                    <Markdown markdown={article} />
                </Box>
            </Stack>
        </PageContainer>
    )
}

export const HowToArticles = [
    {
        title: "Create a Hackathon",
        link: "create",
        article: ''
    },
    {
        title: "Manage Categories",
        link: "categories",
        article: ''
    },
    {
        title: "Submit your Idea",
        link: "submit",
        article: submitIdea
    },
    {
        title: "Cast your Vote",
        link: "vote",
        article: castVote
    },
    {
        title: "Facilitate Presentations",
        link: "present",
        article: facilitatePresentations
    }
]

export const HowToRoutes = (
    <Route element={<HowTo />}>
        <Route path="/how/to" element={<HowToIndex />} />
        {
            HowToArticles.map((article, index) => {
                return (
                    <Route key={index} path={`/how/to/${article.link}`} element={<HowToContainer name={article.title} article={article.article} />} />
                )
            })
        }
    </Route>
)



