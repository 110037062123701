import React, { useCallback } from 'react';
import { getHackathon, HackathonPayload, hackathonsAtom } from '../services/hackathon.service';
import { AxiosError } from 'axios';
import { useAtomValue } from 'jotai';

const findHackathon = (hackathons: Record<string, HackathonPayload>, id: string) => {
    if (!hackathons) {
        return null;
    }

    if (hackathons[id]) {
        return hackathons[id];
    }

    // id could be slug
    return Object.values(hackathons).find(h => h.slug === id) || null;
}

export const useHackathon = (id: string | undefined) => {
    const [reloadRequest, setReloadRequest] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<AxiosError | null>(null);
    const [hackathon, setHackathon] = React.useState<HackathonPayload | null>(null);
    const hackathons = useAtomValue(hackathonsAtom);

    React.useEffect(() => {
        if (id && hackathons) {
            const _ = findHackathon(hackathons, id);
            setHackathon(_);
        }

    }, [hackathons, id])

    React.useEffect(() => {
        if (!id) {
            return;
        }
        setLoading(true);
        getHackathon(id).then(() => {
            setLoading(false);
        }, (err) => {
            setError(err);
            setLoading(false);
        });
    }, [id, reloadRequest]);

    const reload = useCallback(() => {
        setReloadRequest(reloadRequest + 1);
    }, [reloadRequest]);

    if (!id) {
        return {
            hackathon: null,
            loading: false,
            error: null
        }
    }

    return { hackathon, loading, error, reload };
}