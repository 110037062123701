import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import { Loading } from '../../components/Loading';
import { enableVotingPeriod, endVotingPeriod } from '../../services/hackathon.service';
import GavelIcon from '@mui/icons-material/Gavel';
import PeopleIcon from '@mui/icons-material/People';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { JudgeVoteCard } from '../../components/Voting/JudgeVoteCard';
import { useAuth } from '../../services/useAuth';
import { Markdown } from '../../components/Markdown/Markdown';
import { PageContainer } from '../../components/PageContainer';

import HomeIcon from '@mui/icons-material/Home';
import CodeIcon from '@mui/icons-material/Code';
import { JudgeVoteBadge } from '../../components/Voting/JudgeVoteBadge';
import { IdeaSelect } from '../../components/Idea/IdeaSelect';
import Alert from '@mui/material/Alert/Alert';
import Stack from '@mui/material/Stack/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Switch from '@mui/material/Switch/Switch';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import { CategorySortMenu } from '../../components/Voting/CategorySortMenu';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import { IdeaPayload, IdeaScorecard } from '../../services/idea.service';
import { aggregateVotes } from '../../services/vote.service';

/**
 * The purpose of the Judge Page is for judges to view their votes and optionally look at how other judges have voted.
 * 
 * Each column represents a different category that a project is judged on
 * Each row represents a different project
 * 
 * Each cell will contain the score that the judge gave to the project in that category, 
 * as well as a comparison to the average score given by all judges, once there are multiple judge scores.
 * 
 * 
 */

export const JudgePage = () => {
    const { id } = useParams();

    const { hackathon, loading, error, reload } = useHackathon(id);

    const { user } = useAuth();

    const summary = useMemo(() => {
        if (!hackathon) return null;

        return {
            results: hackathon.results,
            categories: hackathon.categories,
        }
    }, [hackathon])

    const [sortCategory, setSortCategory] = React.useState<string | null>(null);
    const [sortType, setSortType] = React.useState<string>('judge');

    const [showJudgeVotes, setShowJudgeVotes] = React.useState<string[]>([]);

    const [highlightedIdea, setHighlightedIdea] = React.useState<string | null>(null);

    const ratedProjects = useMemo(() => {
        if (!hackathon || !summary || !summary.results) return null;

        let projectIds: Set<string> = new Set();
        const results = summary.results;
        results.forEach(result => {
            result.ideas.forEach(idea => {
                projectIds.add(idea.ideaId);
            })
        })
        const presentationOrder = hackathon.presentationOrder || hackathon.ideas.map(i => i._id);
        const projects = presentationOrder.map(_id => hackathon.ideas.find(i => i._id === _id)).filter(i => !!i) as IdeaPayload[];

        const projectResults = projects.map(proj => {
            const projectVotes: { [key: string]: IdeaScorecard } = {}
            results.filter(r => r.ideas.some(idea => idea.ideaId === proj._id)).forEach(r => {
                const scores = r.ideas.find(idea => idea.ideaId === proj._id);
                if (scores) {
                    projectVotes[r.category] = scores;
                }
            })
            return {
                ...proj,
                categories: projectVotes
            }
        })

        return projectResults.sort((a, b) => {
            if (sortCategory) {
                if (sortType === 'participant') {
                    return (b.categories[sortCategory]?.participantVoteAverage || 0) - (a.categories[sortCategory]?.participantVoteAverage || 0)
                } else {
                    return (b.categories[sortCategory]?.judgeVoteAverage || 0) - (a.categories[sortCategory]?.judgeVoteAverage || 0)
                }
            }
            return 0;
        });
    }, [summary, hackathon, sortCategory])

    const ratedProjectElementRefs = useMemo(() => {
        return ratedProjects?.map(proj => React.createRef<HTMLTableRowElement>()) ?? [];
    }, [ratedProjects])

    const isJudgeOrOrganizer = useMemo(() => {
        return user && hackathon && (hackathon.judges.some(j => j.email === user.email) || hackathon.organizers.some(o => o.email === user.email));
    }, [hackathon, user])

    const isJudge = useMemo(() => {
        return user && hackathon && hackathon.judges.some(j => j.email === user.email);
    }, [hackathon, user])

    const isOrganizer = useMemo(() => {
        return user && hackathon && hackathon.organizers.some(o => o.email === user.email);
    }, [hackathon, user])

    const [expandedProjects, setExpandedProjects] = React.useState<string[]>([]);
    const handleExpand = (ideaId: string) => {
        if (expandedProjects.includes(ideaId)) {
            setExpandedProjects(expandedProjects.filter(id => id !== ideaId))
        } else {
            setExpandedProjects([...expandedProjects, ideaId]);
        }
    }

    const judgeOrder = useMemo(() => {
        return hackathon?.judges.map(j => j._id) ?? [];
    }, [hackathon])


    if (loading) {
        return <Loading />
    }

    if (!hackathon || !summary || !summary.results) {
        return null;
    }

    if (!isJudgeOrOrganizer) {
        return <Alert severity='error'>You are not a judge or organizer for this hackathon</Alert>
    }

    if (!ratedProjects || ratedProjects.length === 0) {
        return <Alert severity='info'>No projects have been rated yet</Alert>
    }


    return (
        <PageContainer>
            <Stack direction="column" spacing={2}>
                <AppBreadcrumbs crumbs={[{
                    type: PageType.Home,
                    link: '/',
                    name: 'Home'
                }, {
                    type: PageType.Hackathon,
                    link: `/h/${id}`,
                    name: hackathon?.name || ''
                }, {
                    type: PageType.Judge,
                    name: 'Judging Tool'
                }]} />

                <h1>Judging Tool - {hackathon.name}</h1>
                <Typography>
                    This is a one page view for judges to look at all the rated projects to facilitate discussion amongst judges.
                </Typography>
                {
                    !isJudge && <Alert severity='info'>As an organizer, you may use this page but please note your votes do not influence judges</Alert>
                }
                {error && <Alert severity='error'>{error.message}</Alert>}
                <legend>
                    <Stack direction="column" spacing={1}>
                        <h3>Legend</h3>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{ width: 48, height: 24, backgroundColor: "#8ef" }}></Box>
                            <Typography>Average score from judges</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{ width: 48, height: 24, backgroundColor: "#fd8" }}></Box>
                            <Typography>Average score from participants</Typography>
                        </Stack>
                    </Stack>
                </legend>
                {
                    isOrganizer && (
                        <Stack spacing={2} direction="row">
                            {
                                hackathon.votingPeriodOn && <Button variant="contained" onClick={() => {
                                    // end voting period
                                    endVotingPeriod(hackathon._id).then(() => reload());
                                }}>End Voting</Button>
                            }
                            {
                                !hackathon.votingPeriodOn && (
                                    <Button variant="outlined" onClick={() => {
                                        enableVotingPeriod(hackathon._id).then(() => reload());
                                    }}>Open Voting</Button>
                                )
                            }
                            <Button variant="outlined" onClick={() => {
                                aggregateVotes(hackathon._id).then(() => reload());
                            }}>Refresh Results</Button>
                        </Stack>
                    )
                }

                <table style={{ borderSpacing: 0 }}>
                    <thead style={{
                        backgroundColor: 'white',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        position: 'sticky',
                        top: -1,
                        zIndex: 2,
                    }}>
                        <tr>
                            <th>

                                <Stack padding={1} direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                                    <Typography>Project</Typography>
                                    <IdeaSelect ideas={ratedProjects} onSelect={({ _id }) => {
                                        const index = ratedProjects.findIndex(proj => proj._id === _id);
                                        if (index >= 0) {
                                            ratedProjectElementRefs[index].current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                            setHighlightedIdea(_id);
                                        }
                                    }} />
                                </Stack>
                            </th>
                            {summary.categories.map(cat => (
                                <th
                                    key={cat}
                                >
                                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{
                                        padding: 1,
                                        height: '100%',
                                        width: '100%',
                                    }}>
                                        <Typography>
                                            {cat}</Typography>

                                        <CategorySortMenu selected={sortCategory === cat ? sortType : undefined} onSelect={(type: string) => {
                                            setSortType(type);
                                            setSortCategory(cat);
                                        }} />
                                    </Stack>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody style={{
                        maxHeight: 'calc(100vh - '
                    }}>
                        {
                            ratedProjects.map(({ categories, ...proj }, index) => {
                                const style = {
                                    backgroundColor: highlightedIdea === proj._id ? '#ffffe0' : 'white',
                                }
                                return (
                                    <React.Fragment key={proj._id}>
                                        <tr ref={ratedProjectElementRefs[index]} style={style}>
                                            <td style={{
                                                borderTop: '1px solid #ccc',
                                                verticalAlign: 'top',
                                            }}>
                                                <Stack direction="column" spacing={1}>
                                                    <Typography variant={'h6'}>{proj.title}</Typography>

                                                    {
                                                        !hackathon.blindJudgeScores && (
                                                            <FormControlLabel control={<Switch checked={showJudgeVotes.includes(proj._id)} onChange={(e) => {
                                                                if (e.currentTarget.checked) {
                                                                    setShowJudgeVotes([...showJudgeVotes, proj._id]);
                                                                } else {
                                                                    setShowJudgeVotes(showJudgeVotes.filter(id => id !== proj._id));
                                                                }
                                                            }} />} label="Show Judge Votes" />
                                                        )
                                                    }
                                                </Stack>

                                            </td>
                                            {summary.categories.map(cat => {

                                                if (!categories[cat]) {
                                                    return <td key={cat} style={{
                                                        borderTop: '1px solid #ccc',
                                                    }}></td>
                                                }
                                                return (
                                                    <td key={cat} style={{
                                                        borderTop: '1px solid #ccc',
                                                    }}>
                                                        <Stack direction="column" spacing={2}>
                                                            <Stack direction="row" alignItems={'center'} justifyContent={'center'}>
                                                                <Box sx={{
                                                                    padding: 1,
                                                                    width: 44,
                                                                    backgroundColor: '#8ef',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    {!categories[cat].judgeVoteAverage ? '-' : categories[cat].judgeVoteAverage.toFixed(1)}

                                                                </Box>
                                                                <Box sx={{
                                                                    padding: 1,
                                                                    width: 44,
                                                                    backgroundColor: '#fd8',
                                                                    textAlign: 'center'
                                                                }}>
                                                                    {categories[cat].participantVoteAverage === 0 ? '-' : categories[cat].participantVoteAverage.toFixed(1)}
                                                                </Box>

                                                            </Stack>
                                                            {
                                                                showJudgeVotes.includes(proj._id) && (
                                                                    <Stack direction="row" justifyContent={'center'} spacing={0}>
                                                                        {categories[cat].judgeVotes.map((judgeVote, index) => {
                                                                            if (judgeVote.score === 0) {
                                                                                return <React.Fragment key={index}></React.Fragment>
                                                                            }
                                                                            return (
                                                                                <JudgeVoteBadge key={index} hackathon={hackathon} judgeId={judgeVote.judge ?? ''} score={judgeVote.score} />
                                                                            )
                                                                        })}
                                                                    </Stack>
                                                                )
                                                            }

                                                        </Stack>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        <tr style={style}>
                                            <td colSpan={summary.categories.length + 1} style={{
                                                padding: "16px 0 0 0"
                                            }}>
                                                <Accordion sx={{ backgroundColor: '#f0f0f0' }} expanded={expandedProjects.includes(proj._id)} onChange={() => handleExpand(proj._id)}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Stack direction="row" spacing={1}>
                                                            <PeopleIcon />
                                                            <Typography>
                                                                {proj.members.map(m => m.username).join(', ')}
                                                            </Typography>
                                                        </Stack>
                                                    </AccordionSummary>
                                                    <Stack direction="column" spacing={1} padding={2}>
                                                        <Markdown markdown={proj.description} />
                                                    </Stack>
                                                </Accordion>
                                            </td>
                                        </tr>
                                        <tr style={style}>
                                            <td colSpan={summary.categories.length + 1} style={{
                                                padding: "16px 0"
                                            }}>
                                                <Accordion sx={{ backgroundColor: '#f0f0f0' }} >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        My Vote
                                                    </AccordionSummary>
                                                    <JudgeVoteCard hackathon={hackathon} idea={proj} onSubmit={() => {
                                                        aggregateVotes(hackathon._id);
                                                    }} />
                                                </Accordion>

                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Stack>
        </PageContainer >
    )
}