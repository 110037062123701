
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack/Stack';
import React from 'react';

export const Loading = () => {
    return <Container>
        <Stack spacing={2} paddingTop={10}>
            <CircularProgress />
        </Stack>
    </Container>
}