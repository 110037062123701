import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { HackathonPayload, setCurrentlyPresentingProjectId, updatePresentationOrder, updatePresented } from '../../services/hackathon.service';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { PageContainer } from '../../components/PageContainer';
import Button from '@mui/material/Button/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import Typography from '@mui/material/Typography/Typography';
import { AppBreadcrumbs, PageType } from '../../components/Breadcrumbs/Buttons';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import Alert from '@mui/material/Alert/Alert';
import { useAuth } from '../../services/useAuth';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import CodeIcon from '@mui/icons-material/Code';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from '@mui/icons-material/Error';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

import { LiveVotingBanner } from '../../components/Voting/LiveVotingBanner';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import Paper from '@mui/material/Paper/Paper';
import { HackathonPoller } from '../../services/statepoller.service';
import { IdeaPayload } from '../../services/idea.service';


const Presenter = ({
    hackathonId,
    idea,
    draggable,
    checked,
    isOrganizer,
    onCheck,
}: {
    hackathonId: string,
    idea: IdeaPayload,
    draggable?: boolean,
    checked?: boolean,
    isOrganizer?: boolean,
    onCheck?: (checked: boolean) => void
}) => {
    const navigate = useNavigate();

    const { hackathon } = useHackathon(hackathonId);

    const isCurrentlyPresenting = useMemo(() => {
        if (!hackathon) return null;
        return hackathon.currentlyPresentingProjectId === idea._id;
    }, [hackathon, idea._id]);

    if (!hackathon) return null;

    return (
        <Paper sx={{
            width: '100%'
        }}>

            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    width: '100%'
                }}>
                {
                    draggable && (
                        <DragIndicatorIcon />
                    )
                }


                <Box sx={{
                    minWidth: 40
                }}>
                    <Tooltip title={'Presented'} >
                        <Checkbox
                            disabled={!isOrganizer} checked={checked} onChange={(e) => {
                                onCheck?.(e.target.checked);
                            }} />
                    </Tooltip>
                </Box>

                <Stack direction="column" width="100%">
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={0}>
                        <h4><Link to={`/h/${hackathon.slug}/idea/${idea._id}`}>{idea.title}</Link></h4>
                        <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                            {
                                idea.archived && <Tooltip title={'Archived'}>
                                    <WarningAmberIcon sx={{ minWidth: 40 }} color={'warning'} />
                                </Tooltip>
                            }
                            {
                                idea.disqualified && <Tooltip title={'Disqualified'}>
                                    <ErrorIcon sx={{ minWidth: 40 }} color={'error'} />
                                </Tooltip>
                            }
                            {
                                isOrganizer && (
                                    <IconButton
                                        sx={{ minWidth: 40 }}
                                        title={isCurrentlyPresenting ? 'Clear currently presenting' : 'Set currently presenting'} onClick={() => {
                                            setCurrentlyPresentingProjectId(hackathon._id, isCurrentlyPresenting ? '' : idea._id);
                                        }}>
                                        <CenterFocusStrongIcon color={isCurrentlyPresenting ? 'primary' : 'inherit'} />
                                    </IconButton>
                                )
                            }
                            {
                                !isOrganizer && isCurrentlyPresenting && (<Box sx={{
                                    minWidth: 40,
                                    p: 1
                                }}>
                                    <Tooltip title={'Currently Presenting'}>
                                        <CenterFocusStrongIcon color={'primary'} />
                                    </Tooltip>
                                </Box>)
                            }
                        </Stack>
                    </Stack>
                    {/* <Stack direction="row" flexWrap={'wrap'} spacing={1}>
                    {
                        idea.members.map(m => (
                            <Chip size={'small'} key={m._id} label={m.username} />
                        ))
                    }
                </Stack> */}
                    <Typography>{idea.members.map(m => m.username).join(', ')}</Typography>
                </Stack>


            </Stack>

        </Paper>
    )
}

const PresentersList = ({
    hackathon,
    isOrganizer,
    list,
    presented,
    updatePresented,
    draggable
}: {
    hackathon: HackathonPayload,
    isOrganizer?: boolean,
    list: IdeaPayload[],
    presented?: string[],
    updatePresented?: (presented: string[]) => void,
    draggable?: boolean
}) => {

    const projects = list.map((idea, index) => {
        return {
            hackathonId: hackathon._id,
            idea,
            checked: presented?.includes(idea._id),
            isOrganizer: isOrganizer,
            draggable: isOrganizer,
            onCheck: isOrganizer ? (checked: boolean) => {
                if (checked) {
                    updatePresented?.([...(presented || []), idea._id]);
                } else {
                    updatePresented?.(presented?.filter(p => p !== idea._id) || []);
                }
            } : undefined
        }
    });

    return (
        <Box>
            {
                projects.map((proj, index) => (
                    draggable ? (
                        <Draggable key={proj.idea._id} draggableId={proj.idea._id} index={index}>
                            {(provided) => (
                                <Box
                                    sx={{
                                        m: 1,
                                        background: '#fff',
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <Presenter {...proj} />
                                </Box>
                            )}
                        </Draggable>
                    ) : (
                        <Box
                            key={proj.idea._id}
                            sx={{
                                m: 1,
                                background: '#fff',
                            }}
                        >
                            <Presenter {...proj} />
                        </Box>
                    )

                ))
            }
        </Box>
    )
}

export const Presenters = () => {

    const params = useParams<{ id: string }>();
    const hackathonId = params.id;

    const { hackathon } = useHackathon(hackathonId);
    const { user } = useAuth();

    const [presenting, setPresenting] = useState<IdeaPayload[]>([]);

    const [presented, setPresented] = useState<string[]>([]);

    const desktopMode = useDesktopMode();

    useEffect(() => {
        if (hackathon && hackathon.ideas.length > 0) {
            if (hackathon.presentationOrder) {
                setPresenting(hackathon.presentationOrder.map(id => hackathon.ideas.find(i => i._id === id)).filter(i => i) as IdeaPayload[]);
            }
            if (hackathon.presented) {
                setPresented(hackathon.presented)
            }
        }
    }, [hackathon])

    const notPresenting = useMemo(() => {
        if (!hackathon || !hackathon.ideas) return [];

        if (presenting) {
            return hackathon.ideas.filter(i => !presenting.some(p => p._id === i._id));
        }

        return hackathon.ideas;
    }, [hackathon, presenting])

    const navigate = useNavigate();

    const isOrganizer = useMemo(() => {
        return !!user && hackathon?.organizers.some(o => o.email === user.email);
    }, [hackathon, user])

    if (!hackathonId || !hackathon) return null;

    if (!desktopMode) {
        return (
            <PageContainer>
                <Alert severity={'warning'}>This feature is not available on mobile devices.</Alert>
            </PageContainer>
        )
    }

    return (
        <PageContainer>
            <Stack direction="column" spacing={2}>
                <AppBreadcrumbs crumbs={[
                    {
                        type: PageType.Home,
                        link: '/',
                        name: 'Home'
                    },
                    {
                        type: PageType.Hackathon,
                        link: `/h/${hackathonId}`,
                        name: hackathon?.name || ''
                    },
                    {
                        type: PageType.PresentationOrder,
                        name: 'Presentation Order'
                    }
                ]} />
                <LiveVotingBanner hackathonId={hackathon._id} />
                <h1>Presentation Order</h1>
                <Typography>
                    Drag and drop the presenters to reorder them.
                </Typography>

                {
                    !presenting.length && (
                        <Alert severity={'warning'} action={<Button onClick={() => {
                            const order = hackathon.ideas.filter(i => (
                                i.members.length > 0 &&
                                !i.disqualified &&
                                !i.archived
                            ))
                            setPresenting(order);
                            updatePresentationOrder(hackathonId, order.map(o => o._id));
                        }}>Auto order</Button>}>
                            An order has not been set. Click the button to automatically order the presenters.
                        </Alert>
                    )
                }

                <DragDropContext
                    onDragEnd={(result: DropResult) => {
                        if (!result.destination) return;
                        // updateOrder(newOrder);
                        if (result.destination.droppableId === 'presenting') {
                            if (result.source.droppableId === 'notPresenting') {
                                // add to presenting
                                const order = [...presenting];
                                order.splice(result.destination.index, 0, notPresenting[result.source.index]);
                                setPresenting(order);
                                updatePresentationOrder(hackathonId, order.map(o => o._id));
                            } else {
                                // reorder
                                const order = [...presenting];
                                const [removed] = order.splice(result.source.index, 1);
                                order.splice(result.destination.index, 0, removed);
                                setPresenting(order);
                                updatePresentationOrder(hackathonId, order.map(o => o._id));
                            }
                        } else if (result.source.droppableId === 'presenting') {
                            // remove from presenting
                            const order = [...presenting];
                            order.splice(result.source.index, 1);
                            setPresenting(order);
                            updatePresentationOrder(hackathonId, order.map(o => o._id));
                        }

                    }}
                >
                    <Stack direction="row" spacing={4} width="100%">

                        <Droppable droppableId="notPresenting">
                            {
                                (provided, snapshot) => (
                                    <div style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#f0faff' : 'transparent',
                                        boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                                        padding: 8,
                                        width: 'calc(50% - 32px)'
                                    }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <h3>Not Presenting ({notPresenting.length})</h3>
                                        <PresentersList
                                            draggable={isOrganizer}
                                            isOrganizer={isOrganizer}
                                            hackathon={hackathon}
                                            list={notPresenting} />
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>

                        <Droppable droppableId="presenting">
                            {
                                (provided, snapshot) => (
                                    <div style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#f0faff' : 'transparent',
                                        boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                                        padding: 8,
                                        width: 'calc(50% - 32px)'
                                    }} ref={provided.innerRef} {...provided.droppableProps}>

                                        <h3>Presenting ({presenting.length})</h3>

                                        <PresentersList
                                            hackathon={hackathon}
                                            list={presenting}
                                            isOrganizer={isOrganizer}
                                            presented={presented}
                                            draggable={isOrganizer}
                                            updatePresented={(_presented) => {
                                                setPresented(_presented);
                                                updatePresented(hackathonId, _presented);
                                            }} />
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    </Stack>

                </DragDropContext>
            </Stack>

        </PageContainer>
    )
}

export const PresentationList = ({
    hackathonId
}: {
    hackathonId: string
}) => {

    // use a mechanism like the live voting banner to regularly fetch updates

    const { user } = useAuth();
    const { hackathon } = useHackathon(hackathonId);

    const [presenting, setPresenting] = useState<string[]>([]);
    const [presented, setPresented] = useState<string[]>([]);
    const isOrganizer = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return hackathon.organizers.some(o => o.email === user.email);
    }, [user, hackathon])

    useEffect(() => {
        if (hackathon) {
            if (hackathon.presentationOrder) {
                setPresenting(hackathon.presentationOrder);
            }
            if (hackathon.presented) {
                setPresented(hackathon.presented)
            }
        }

    }, [hackathon])

    const update = useCallback((payload: {
        currentlyPresentingProjectId: string,
        votingPeriodOn: boolean,
        presentationOrder: string[],
        presented: string[]
    }) => {
        setPresenting(payload.presentationOrder);
        setPresented(payload.presented);
    }, [])

    useEffect(() => {
        if (!hackathon) return;

        const sub = HackathonPoller.subscribe(hackathon?.slug, ({
            currentlyPresentingProjectId,
            votingPeriodOn,
            presentationOrder,
            presented
        }) => {
            update({
                currentlyPresentingProjectId,
                votingPeriodOn,
                presentationOrder,
                presented
            })
        })

        return () => {
            sub?.unsubscribe();
        }
    }, [hackathonId, update])

    const presentationOrder = useMemo(() => {
        return presenting.map(p => hackathon?.ideas.find(i => i._id === p)).filter(i => i) as IdeaPayload[];
    }, [presenting, hackathon])

    if (!hackathon) return <div>No hackathon</div>;

    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
                <h3>Presenting ({presentationOrder.length})</h3>
            </Stack>
            {
                isOrganizer && (
                    <DragDropContext
                        onDragEnd={(result: DropResult) => {
                            if (!result.destination) return;
                            // reorder
                            const order = [...presentationOrder];
                            const [removed] = order.splice(result.source.index, 1);
                            order.splice(result.destination.index, 0, removed);
                            setPresenting(order.map(o => o._id));
                            updatePresentationOrder(hackathonId, order.map(o => o._id));
                        }}
                    >
                        <Droppable droppableId="presenting">
                            {
                                (provided, snapshot) => (
                                    <div style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#f0faff' : 'transparent',
                                        boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                                        padding: 8,
                                        width: hackathon.votingPeriodOn ? '100%' : 'calc(50% - 32px)'
                                    }} ref={provided.innerRef} {...provided.droppableProps}>
                                        <PresentersList
                                            hackathon={hackathon}
                                            list={presentationOrder}
                                            isOrganizer={isOrganizer}
                                            presented={presented}
                                            draggable={isOrganizer}
                                            updatePresented={(_presented) => {
                                                setPresented(_presented);
                                                updatePresented(hackathonId, _presented);
                                            }} />
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>

                    </DragDropContext>
                )
            }
            {!isOrganizer && (
                <PresentersList
                    hackathon={hackathon}
                    list={presentationOrder}
                    isOrganizer={false}
                    presented={presented} />
            )}

        </Stack>
    )

}
