import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const IdeaSelect = ({
    ideas,
    onSelect
}: {
    ideas: Array<{ _id: string, title: string }>,
    onSelect: (idea: { _id: string, title: string }) => void
}) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {ideas.map((idea) => (
                    <MenuItem key={idea._id} onClick={() => {
                        onSelect(idea);
                        handleClose();
                    }}>
                        {idea.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}