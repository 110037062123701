import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import { DatePicker } from '@mui/x-date-pickers';
import { createHackathon } from '../../services/hackathon.service';
import Editor from '../Editor/Editor';

import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { PageContainer } from '../PageContainer';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import Stack from '@mui/material/Stack/Stack';
import Box from '@mui/material/Box/Box';
import ListItem from '@mui/material/ListItem/ListItem';
import IconButton from '@mui/material/IconButton/IconButton';
import TextField from '@mui/material/TextField/TextField';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import FormControl from '@mui/material/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import Slider from '@mui/material/Slider/Slider';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import List from '@mui/material/List/List';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';

const preferredAuthOptions = ['google'];

export interface EditHackathonData {
    name: string;
    description: string;
    startDate: dayjs.Dayjs | null;
    endDate: dayjs.Dayjs | null;
    ideaSubmissionDeadline: dayjs.Dayjs | null;
    ideaTemplate: string;
    categories: string[];
    categoryMeta: Array<{ judgeOnly: boolean, description: string }>;
    inviteCode: string;
    domain: string;
    preferredAuth: string;
    minTeamSize: number;
    maxTeamSize: number;
    blindJudgeScores: boolean;
    anonymousJudgeScores: boolean;
    participantScores: boolean;
}

const Item = ({ provided, isDragging, children }: { provided: any, isDragging: boolean, children: React.ReactNode }) => {
    return <Stack
        direction="row"
        alignItems="center"
        sx={{
            width: '100%',
            backgroundColor: 'white'
        }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
    >
        <DragIndicatorIcon />
        <Box sx={{ width: '100%' }}>
            {children}
        </Box>
    </Stack>
}

const CategoryItem = ({
    data,
    setData,
    category,
    index,
    desktopMode
}: {
    data: EditHackathonData,
    setData: (payload: EditHackathonData) => void,
    category: string,
    index: number,
    desktopMode: boolean
}) => {
    return (
        <ListItem sx={{
            backgroundColor: '#fff',
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        }} secondaryAction={
            <IconButton onClick={() => {
                const newCategories = [...data.categories];
                newCategories.splice(index, 1);
                setData({ ...data, categories: newCategories });
            }}>
                <DeleteIcon />
            </IconButton>
        }>
            <Stack direction={desktopMode ? 'row' : 'column'} spacing={1} sx={{ width: 'calc(100% - 32px)', py: 2 }}>

                <Stack direction="column" spacing={0}>
                    <TextField label="Category Name" sx={{ minWidth: 200 }} value={category} onChange={(e) => {
                        const newCategories = [...data.categories];
                        newCategories[index] = e.target.value;
                        setData({ ...data, categories: newCategories });
                    }} />

                    <FormControlLabel control={<Checkbox checked={data.categoryMeta[index].judgeOnly} onChange={(e) => {
                        setData({
                            ...data,
                            categoryMeta: data.categoryMeta.map((meta, i) => {
                                if (i === index) {
                                    return { ...meta, judgeOnly: e.target.checked }
                                }
                                return meta;
                            })
                        })


                    }} />} label={'Judges Only'} />
                </Stack>

                <TextField label="Description" multiline={true} minRows={3} fullWidth={true} value={data.categoryMeta[index].description} onChange={(e) => {
                    const newCategories = [...data.categoryMeta];
                    newCategories[index] = { ...newCategories[index], description: e.target.value };
                    setData({ ...data, categoryMeta: newCategories });
                }} />
            </Stack>
        </ListItem>
    )
}

export const HackathonEditor = ({
    data,
    setData,
    onSubmit,
    headingLabel,
    submitLabel
}: {
    data: EditHackathonData,
    setData: (payload: EditHackathonData) => void,
    onSubmit: () => void,
    headingLabel: string,
    submitLabel: string
}) => {

    const desktopMode = useDesktopMode();

    const [newCat, setNewCat] = useState<{
        label: string,
        description: string,
        judgeOnly: boolean
    }>({
        label: '',
        description: '',
        judgeOnly: false
    });

    const [error, setError] = useState<string>('');

    return (
        <DragDropContext onDragEnd={(result: DropResult<string>) => {
            if (!result.destination) {
                return;
            }
            const items = [...data.categories];
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);

            const metaItems = [...data.categoryMeta];
            const [reorderedMetaItem] = metaItems.splice(result.source.index, 1);
            metaItems.splice(result.destination.index, 0, reorderedMetaItem);

            setData({ ...data, categories: items, categoryMeta: metaItems });
        }}>
            <PageContainer sx={{ minHeight: '80vh' }}>
                <Stack spacing={2}>
                    <h1>{headingLabel}</h1>
                    <TextField label="Name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Editor toolbar={true} value={data.description} onChange={value => setData({ ...data, description: value })} />
                    </React.Suspense>
                    <h2>Dates</h2>
                    <Stack direction={desktopMode ? 'row' : 'column'} spacing={2}>
                        <DatePicker label="Idea Submission Deadline" value={data.ideaSubmissionDeadline} onChange={date => setData({ ...data, ideaSubmissionDeadline: date })} />
                        <DatePicker label="Start Date" value={data.startDate} onChange={date => setData({ ...data, startDate: date })} />
                        <DatePicker label="End Date" value={data.endDate} onChange={date => setData({ ...data, endDate: date })} />
                    </Stack>
                    <h2>Team</h2>
                    <Stack direction="row">
                        <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: 1 }}>
                            <FormControl>
                                <FormLabel>Team Size ({data.minTeamSize} - {data.maxTeamSize})</FormLabel>

                                <Slider sx={{ width: 212 }} value={[data.minTeamSize, data.maxTeamSize]} onChange={(e, value) => {
                                    const vals = value as number[];
                                    const min = vals[0] || 1;
                                    const max = vals[1] || 10;
                                    setData({ ...data, minTeamSize: min, maxTeamSize: max })
                                }} valueLabelDisplay="auto" min={1} max={10} />
                            </FormControl>
                        </Box>
                    </Stack>
                    <h2>Security</h2>
                    <Stack direction={desktopMode ? 'row' : 'column'} spacing={4}>

                        <Stack direction='row' alignItems={'center'} spacing={0}>
                            <TextField label="Invite Code" value={data.inviteCode} onChange={e => setData({ ...data, inviteCode: e.target.value })} />
                            <Tooltip title="Set an invite code to make this hackathon invite-only">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction='row' alignItems={'center'} spacing={0}>
                            <TextField label="Domain" value={data.domain} onChange={e => setData({ ...data, domain: e.target.value })} />
                            <Tooltip title="Set a domain to limit who can join this hackathon by user email domain">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction='row' alignItems={'center'} spacing={0}>
                            <Select label="Preferred Auth"
                                value={data.preferredAuth || 'None'}
                                onChange={e => setData({ ...data, preferredAuth: e.target.value })}
                            >
                                <MenuItem key="none" value="None">None</MenuItem>
                                {
                                    preferredAuthOptions.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))
                                }
                            </Select>
                            <Tooltip title="Set a preferred auth method for this hackathon">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                    <h2>Judging</h2>

                    <List>
                        <ListItem>
                            <h3>Categories</h3>
                        </ListItem>
                        <Box>
                            <Droppable droppableId="categories" renderClone={(provided, snapshot, rubic) => {
                                return <Item provided={provided} isDragging={true}>
                                    <CategoryItem data={data} setData={setData} category={data.categories[rubic.source.index]} index={rubic.source.index} desktopMode={desktopMode} />
                                </Item>
                            }} >
                                {
                                    (provided, snapshot) => (
                                        <div style={{
                                            backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                                        }} ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                data.categories.map((category, index) => (
                                                    <Draggable key={category} draggableId={category} index={index}>
                                                        {
                                                            (provided) => (
                                                                <Item provided={provided} isDragging={false}>
                                                                    <CategoryItem data={data} setData={setData} category={category} index={index} desktopMode={desktopMode} />
                                                                </Item>
                                                            )
                                                        }
                                                    </Draggable>

                                                ))
                                            }

                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            </Droppable>
                        </Box>

                        <ListItem secondaryAction={
                            <Button disabled={!newCat || data.categories.some(c => c === newCat.label)} onClick={() => {
                                if (newCat) {
                                    setData({
                                        ...data,
                                        categories: [...data.categories, newCat.label],
                                        categoryMeta: [...data.categoryMeta, {
                                            judgeOnly: newCat.judgeOnly,
                                            description: newCat.description
                                        }]
                                    });
                                    setNewCat({
                                        label: '',
                                        description: '',
                                        judgeOnly: false
                                    });
                                }
                            }}>
                                Add
                            </Button>
                        }>
                            <Stack direction={desktopMode ? 'row' : 'column'} spacing={1} sx={{ width: 'calc(100% - 32px)' }}>
                                <Stack direction="column" spacing={0}>
                                    <TextField label="Category Name" value={newCat.label} sx={{ minWidth: 200 }} onChange={(e) => {
                                        setNewCat((_) => ({ ..._, label: e.target.value }));
                                    }} />
                                    <FormControlLabel control={<Checkbox checked={newCat.judgeOnly} onChange={(e) => {
                                        setNewCat((_) => ({ ..._, judgeOnly: e.target.checked }));
                                    }} />} label={'Judges Only'} />
                                </Stack>

                                <TextField label="Description" multiline={true} minRows={3} fullWidth={true} value={newCat.description} onChange={(e) => {
                                    setNewCat((_) => ({ ..._, description: e.target.value }));
                                }} />
                            </Stack>
                        </ListItem>
                    </List>


                    <Stack direction="column" spacing={2}>
                        <Stack direction="row">

                            <FormControlLabel control={<Checkbox checked={data.participantScores} onChange={(e) => {
                                setData({ ...data, participantScores: e.target.checked })
                            }} />} label={'Participant Scores'} />
                            <Tooltip title="Participants may submit scores">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction="row">
                            <FormControlLabel control={<Checkbox checked={data.blindJudgeScores} onChange={(e) => {
                                setData({ ...data, blindJudgeScores: e.target.checked })
                            }} />} label={'Blind Judge Scores'} />
                            <Tooltip title="Judges do not see other judge scores">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction="row">
                            <FormControlLabel control={<Checkbox checked={data.anonymousJudgeScores} onChange={(e) => {
                                setData({ ...data, anonymousJudgeScores: e.target.checked })
                            }} />} label={'Anonymous Judge Scores'} />
                            <Tooltip title="Judge scores are anonymous">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>

                    </Stack>

                    <h2>Idea Template (Optional)</h2>
                    <Typography>Set a template here for idea submissions</Typography>
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Editor toolbar={true} value={data.ideaTemplate} onChange={value => setData({ ...data, ideaTemplate: value })} />
                    </React.Suspense>

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (!data.name) {
                            setError('Name is required');
                            return;
                        }

                        onSubmit();
                    }}>
                        <Stack direction="row" spacing={2}>
                            <Button type="submit" variant='contained'>{submitLabel}</Button>
                            <Button onClick={() => {
                                window.history.back();
                            }} variant='outlined'>Cancel</Button>
                        </Stack>
                    </form>
                    <br />
                </Stack>
            </PageContainer >

        </DragDropContext>
    )
}