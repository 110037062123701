import React, { useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import { JoinHackathon } from '../../components/Invite/JoinHackathon';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../services/useAuth';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import GavelIcon from '@mui/icons-material/Gavel';
import { IdeasList } from '../../components/Idea/IdeasList';
import { Markdown } from '../../components/Markdown/Markdown';
import { login } from '../../services/auth.service';
import { LiveVotingBanner } from '../../components/Voting/LiveVotingBanner';
import { PageContainer } from '../../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import IconButton from '@mui/material/IconButton/IconButton';

import PollIcon from '@mui/icons-material/Poll';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab/Fab';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import Box from '@mui/material/Box/Box';
import { AppBreadcrumbs, HomeButton, PageType } from '../../components/Breadcrumbs/Buttons';
import { ScrollTopButton } from '../../components/ScrollTopButton';
import { PresentationNavButton } from '../../components/Idea/PresentationNavButton';

export const HackathonPage = () => {
    const { id } = useParams();

    const { hackathon, loading, error, reload } = useHackathon(id);

    const { user, loading: authLoading } = useAuth();

    const navigate = useNavigate();

    const desktopMode = useDesktopMode();

    const isOrganizer = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }

        return user && hackathon.organizers.some(o => o.email === user.email)
    }, [user, hackathon])

    const isJudge = useMemo(() => {
        if (!user || !hackathon) {
            return false;
        }
        return user && hackathon.judges.some(j => j.email === user.email)
    }, [user, hackathon])

    if (!id) {
        return <div>No id provided</div>
    }

    if (!hackathon && loading) {
        return <Loading />
    }


    if (error) {
        console.error(error);
        if (error.response?.status === 403) {
            // User is not authorized, maybe join
            const data = error.response?.data as any;
            const needsInviteCode = data.needsInviteCode || false;
            const hackathonName = data.hackathonName || 'hackathon';
            const hackathonDomain = data.hackathonDomain || '';
            const preferredAuth = data.preferredAuth || '';

            const matchDomain = !hackathonDomain || (hackathonDomain && user && user.email.endsWith(hackathonDomain));

            return <PageContainer>
                <Stack spacing={2}>
                    <h1>{hackathonName}</h1>
                    {
                        !matchDomain && <Alert severity='error'>
                            This hackathon is restricted to {hackathonDomain} emails. Please sign out and sign in with a {hackathonDomain} email
                        </Alert>
                    }
                    {
                        matchDomain && (
                            <>
                                <JoinHackathon
                                    hackathonId={id}
                                    needsInviteCode={needsInviteCode} />
                            </>
                        )
                    }

                </Stack>
            </PageContainer>
        } else if (error.response?.status === 401) {

            // User is not authorized, maybe join
            const data = error.response?.data as any;
            const needsInviteCode = data.needsInviteCode || false;
            const hackathonDomain = data.hackathonDomain || '';
            const hackathonName = data.hackathonName || 'hackathon';

            const preferredAuth = data.preferredAuth || '';
            return <PageContainer>
                <Stack spacing={2}>
                    <h1>{hackathonName}</h1>
                    <div>Please sign in {hackathonDomain ? `with a ${hackathonDomain} email` : ''} to see this page</div>
                    <Stack direction="row">
                        <Button onClick={() => login(window.location.pathname, preferredAuth)}>Log in or sign up</Button>
                    </Stack>
                </Stack>
            </PageContainer>

        }

        return <PageContainer>
            <Stack spacing={2}>
                <h1>Error</h1>
                <p>Why not create your own hackathon? <Link to="/new">Create</Link></p>
            </Stack>
        </PageContainer >
    }


    if (!hackathon) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>Hackathon not found</h1>
            </Stack>
        </PageContainer>
    }


    return (
        <PageContainer>
            <Stack spacing={2}>
                <LiveVotingBanner hackathonId={id} />

                <AppBreadcrumbs crumbs={[
                    { type: PageType.Home, name: 'Home', link: '/' },
                    { type: PageType.Hackathon, name: hackathon.name }
                ]} />
                <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                    <h1>{hackathon.name}</h1>

                    <Stack spacing={2} direction="row" flexWrap={'wrap'}>
                        {
                            (isOrganizer) &&
                            <>
                                <IconButton
                                    title="Edit"
                                    onClick={() => {
                                        navigate(`/h/${id}/edit`)
                                    }}>
                                    <EditIcon />
                                </IconButton>


                                <IconButton
                                    title="Organize"
                                    onClick={() => {
                                        navigate(`/h/${id}/organizer`)
                                    }}>
                                    <PeopleIcon />
                                </IconButton>
                            </>
                        }
                        {
                            (isJudge || isOrganizer) &&
                            <>
                                <IconButton
                                    title="Judging"
                                    onClick={() => {
                                        navigate(`/h/${id}/judge`)
                                    }}>
                                    <GavelIcon />
                                </IconButton>
                            </>
                        }
                        {
                            (isJudge || isOrganizer) && !hackathon.votingPeriodOn && hackathon.winners.length > 0 && (
                                <>
                                    <IconButton title="Vote Results" onClick={() => {
                                        navigate(`/h/${id}/vote`)
                                    }}>
                                        <PollIcon />
                                    </IconButton>
                                </>
                            )
                        }
                        {
                            isOrganizer && (
                                <IconButton title="Presenters" onClick={() => {
                                    navigate(`/h/${id}/presenters`)
                                }}>
                                    <LowPriorityIcon />
                                </IconButton>
                            )
                        }

                    </Stack>
                </Stack>
                <Markdown markdown={hackathon.description} />
                <div className="dates">
                    <Stack direction="column" spacing={2}>
                        {
                            hackathon.ideaSubmissionDeadline && (<Stack direction={'row'} alignItems="center" spacing={2}>
                                <Typography>Idea Submission Deadline: {hackathon.ideaSubmissionDeadline.toLocaleString()}</Typography>
                                {
                                    hackathon.ideaSubmissionDeadline.getTime() > Date.now() && hackathon.ideaSubmissionDeadline.getTime() - Date.now() < 1000 * 60 * 60 * 48 && <Alert severity="warning">Idea submission is closing in {
                                        Math.floor((hackathon.ideaSubmissionDeadline.getTime() - Date.now()) / (1000 * 60 * 60))
                                    } hours</Alert>
                                }
                                {
                                    hackathon.ideaSubmissionDeadline.getTime() < Date.now() && <Alert severity="error">Idea submission is closed</Alert>
                                }

                            </Stack>)
                        }

                        {
                            hackathon.startDate && <Box>Start Date: {hackathon.startDate.toLocaleDateString()}</Box>
                        }
                        {
                            hackathon.endDate && <Box>End Date: {hackathon.endDate.toLocaleDateString()}</Box>
                        }
                    </Stack>
                </div>
                <div className="judges">
                    <Stack direction="column" spacing={2}>
                        <h3>Voting</h3>
                        <p>
                            {hackathon.participantScores ? '🙋Participants can vote. Participant votes are always anonymous' : 'Participants cannot vote.'}
                        </p>
                        <p>
                            {
                                hackathon.anonymousJudgeScores ? 'Judges votes are anonymous' : 'Judges votes are not anonymous'
                            }
                        </p>
                        {
                            hackathon.judges.length > 0 && (
                                <>
                                    <h4>Judge{hackathon.judges.length > 1 ? 's' : ''}</h4>
                                    <ol>
                                        {
                                            hackathon.judges.map(judge => (
                                                <li key={judge.email}>{judge.username} ({judge.email})</li>
                                            ))
                                        }
                                    </ol>
                                </>
                            )
                        }

                    </Stack>
                </div>
                <IdeasList hackathon={hackathon} reloadHackathon={() => reload()} />

                {
                    !hackathon.votingPeriodOn && hackathon.ideas.length > 2 && <ScrollTopButton window={() => window} />
                }
                {
                    hackathon.votingPeriodOn && <PresentationNavButton hackathonId={hackathon.slug} />
                }
                <Stack spacing={2}>
                    &nbsp;
                </Stack>
            </Stack>
        </PageContainer>
    )
}