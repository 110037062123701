
import React, { useState } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Fab from '@mui/material/Fab/Fab';
import Menu from '@mui/material/Menu/Menu';
import { PresentationList } from '../../pages/HackathonPage/Presenters';
import { useHackathon } from '../../hooks/useHackathon';
import Box from '@mui/material/Box/Box';
export const PresentationNavButton = ({
    hackathonId
}: {
    hackathonId: string
}) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { hackathon } = useHackathon(hackathonId);
    if (!hackathon || !hackathon.votingPeriodOn) {
        return null;
    }

    return (
        <>
            <Fab sx={{
                position: 'fixed',
                bottom: 16,
                right: 16
            }} size={'small'} color={'info'} onClick={handleClick}>
                <ListAltIcon />
            </Fab>
            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}>
                <Box sx={{
                    width: 360,
                    p: 1
                }}>
                    <PresentationList hackathonId={hackathon.slug} />
                </Box>
            </Menu >
        </>

    )
}