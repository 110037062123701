import { User } from "./auth.service";
import axiosService from "./axios.service";
import { atom } from 'jotai';
import { store } from './atom.service';
import { VotePayload } from "../models/vote.model";
import { convertDataToIdeaPayload, IdeaPayload, IdeaScorecard } from "./idea.service";
import { CommentPayload } from "./comment.service";
import { convertDataToUser } from "./user.service";
import { HackathonPoller } from "./statepoller.service";

export const currentHackathonAtom = atom<HackathonPayload | null>(null);

export const hackathonsAtom = atom<Record<string, HackathonPayload>>({});

store.set(hackathonsAtom, {});

export interface HackathonOptions {
    inviteCode: string;
    name: string;
    description: string;
    startDate?: Date;
    endDate?: Date;
    ideaSubmissionDeadline?: Date;
    categories: string[];
    maxTeamSize: number;
    minTeamSize: number;
    domain: string;
    participantScores?: boolean;
    blindJudgeScores?: boolean;
    anonymousJudgeScores?: boolean;
}

export interface HackathonPayload {
    _id: string;
    slug: string;
    inviteCode: string;
    domain: string;
    preferredAuth: string;
    name: string;
    description: string;
    startDate: Date | null;
    endDate: Date | null;
    ideaSubmissionDeadline: Date | null;
    ideaTemplate?: string;
    categories: string[];
    categoryMeta: Array<{
        label: string;
        description: string;
        judgeOnly: boolean;
    }>;
    participants: User[];
    judges: User[];
    organizers: User[];
    ideas: IdeaPayload[];
    maxTeamSize: number;
    minTeamSize: number;
    winners: IdeaPayload[];
    participantScores?: boolean;
    blindJudgeScores?: boolean;
    anonymousJudgeScores?: boolean;
    votingPeriodOn?: boolean;
    results?: Array<{
        category: string;
        ideas: Array<IdeaScorecard>
    }>;
    currentlyPresentingProjectId?: string;
    presentationOrder?: string[];
    presented?: string[]
}

const shouldReturnCached = new Map<string, boolean>();

const ongoingRequests = new Map<string, Promise<any>>();

const markHackathonUpdated = (id: string) => {
    shouldReturnCached.set(id, true);
    setTimeout(() => shouldReturnCached.set(id, false), 1000);
}


export const createHackathon = async (options: HackathonOptions) => {
    const { data } = await axiosService.post('/api/hackathons/new', options);
    return convertDataToHackathonPayload(data);
}

export const getHackathon = async (id: string) => {
    if (shouldReturnCached.get(id) && store.get(hackathonsAtom)[id]) {
        return store.get(hackathonsAtom)[id];
    }

    let promise;
    if (ongoingRequests.get(id)) {
        promise = ongoingRequests.get(id);
    } else {
        promise = axiosService.get(`/api/hackathons/${id}`);
        ongoingRequests.set(id, promise);
    }

    const { data } = await promise?.catch((e) => {
        ongoingRequests.delete(id);
        throw e;
    });
    ongoingRequests.delete(id);
    return convertDataToHackathonPayload(data);
}

export const updateHackathon = async (id: string, options: HackathonOptions) => {

    const { data } = await axiosService.put(`/api/hackathons/${id}`, options);

    return convertDataToHackathonPayload(data);
}

export const joinHackathon = async (id: string, inviteCode: string) => {
    const { data } = await axiosService.post(`/api/hackathons/join/${id}`, { inviteCode });

    return convertDataToHackathonPayload(data);
}

export const removeParticipantFromHackathon = async (hackathonId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/removeparticipant`, { email });

    return convertDataToHackathonPayload(data);
}

export const addOrganizerToHackathon = async (hackathonId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/addorganizer`, { email });

    return convertDataToHackathonPayload(data);
}

export const removeOrganizerFromHackathon = async (hackathonId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/removeorganizer`, { email });

    return convertDataToHackathonPayload(data);
}

export const addJudgeToHackathon = async (hackathonId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/addjudge`, { email });

    return convertDataToHackathonPayload(data);
}

export const removeJudgeFromHackathon = async (hackathonId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/removejudge`, { email });

    return convertDataToHackathonPayload(data);
}


export const getMyHackathons = async () => {
    const { data } = await axiosService.get(`/api/hackathons/mine`);
    return data.map(convertDataToHackathonPayload);
}

export const enableVotingPeriod = async (hackathonId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/openvoting`);
    HackathonPoller.maybeUpdate(hackathonId, data);
    return convertDataToHackathonPayload(data);
}

export const disableVotingPeriod = async (hackathonId: string) => {

    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/closevoting`);
    HackathonPoller.maybeUpdate(hackathonId, data);
    return convertDataToHackathonPayload(data);
}

export const endVotingPeriod = async (hackathonId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/endvoting`);
    HackathonPoller.maybeUpdate(hackathonId, data);
    return convertDataToHackathonPayload(data);
}

export const updatePresentationOrder = async (hackathonId: string, order: string[]) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/presentation_order`, { order });
    HackathonPoller.maybeUpdate(hackathonId, data);
    return convertDataToHackathonPayload(data);
}

export const updatePresented = async (hackathonId: string, presented: string[]) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/presented`, { presented });
    HackathonPoller.maybeUpdate(hackathonId, data);
    return convertDataToHackathonPayload(data);
}

export const convertDataToHackathonPayload = (data: any): HackathonPayload => {
    const payload: HackathonPayload = {
        _id: data._id,
        slug: data.slug,
        inviteCode: data.inviteCode,
        domain: data.domain,
        preferredAuth: data.preferredAuth,
        name: data.name,
        description: data.description,
        startDate: data.startDate ? new Date(data.startDate) : null,
        endDate: data.endDate ? new Date(data.endDate) : null,
        ideaSubmissionDeadline: data.ideaSubmissionDeadline ? new Date(data.ideaSubmissionDeadline) : null,
        ideaTemplate: data.ideaTemplate,
        categories: data.categories,
        categoryMeta: data.categoryMeta,
        participants: data.participants.map(convertDataToUser),
        organizers: data.organizers.map(convertDataToUser),
        judges: data.judges,
        ideas: data.ideas.map(convertDataToIdeaPayload),
        maxTeamSize: data.maxTeamSize,
        minTeamSize: data.minTeamSize,
        winners: data.winners.map(convertDataToIdeaPayload),
        participantScores: data.participantScores,
        blindJudgeScores: data.blindJudgeScores,
        anonymousJudgeScores: data.anonymousJudgeScores,
        votingPeriodOn: data.votingPeriodOn,
        results: data.results,
        currentlyPresentingProjectId: data.currentlyPresentingProjectId || "",
        presentationOrder: data.presentationOrder,
        presented: data.presented
    }

    if (payload.categoryMeta.length !== payload.categories.length) {
        payload.categoryMeta = payload.categories.map((category: string) => ({
            label: category,
            description: '',
            judgeOnly: false
        }))
    }

    store.set(hackathonsAtom, {
        ...store.get(hackathonsAtom),
        [data._id]: payload,
        [data.slug]: payload
    });

    if (currentHackathonAtom && store.get(currentHackathonAtom)?._id === data._id) {
        store.set(currentHackathonAtom, payload);
    }

    markHackathonUpdated(data._id);
    markHackathonUpdated(data.slug);
    return payload
}

const updateHackathonFields = (id: string, data: Partial<HackathonPayload>) => {
    const hackathon = store.get(hackathonsAtom)[id];
    if (!hackathon) return;

    store.set(hackathonsAtom, {
        ...store.get(hackathonsAtom),
        [id]: {
            ...hackathon,
            ...data
        }
    });

    markHackathonUpdated(id);
    markHackathonUpdated(hackathon.slug);

    const currentHackathon = store.get(currentHackathonAtom);
    if (!currentHackathon) return;

    if (currentHackathon && currentHackathon._id === id) {
        store.set(currentHackathonAtom, {
            ...currentHackathon,
            ...data
        });
    }

}
export const setCurrentlyPresentingProjectId = async (hackathonId: string, ideaId: string): Promise<string> => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/current_project`, { projectId: ideaId });

    updateHackathonFields(hackathonId, data);
    HackathonPoller.maybeUpdate(hackathonId, data);
    return data.currentlyPresentingProjectId as string;
}

export const getCurrentlyPresentingProjectId = async (hackathonId: string): Promise<{
    currentlyPresentingProjectId: string,
    votingPeriodOn: boolean,
    presentationOrder: string[],
    presented: string[]
}> => {
    const { data } = await axiosService.get(`/api/hackathons/${hackathonId}/current_project`);

    updateHackathonFields(hackathonId, data);

    return {
        currentlyPresentingProjectId: data.currentlyPresentingProjectId as string ?? '',
        votingPeriodOn: data.votingPeriodOn as boolean ?? false,
        presentationOrder: data.presentationOrder,
        presented: data.presented
    }
}


