import React from 'react';
import { login } from '../services/auth.service';

import './Home.scss';
import { useAuth } from '../services/useAuth';
import { useDesktopMode } from '../services/hooks/useDesktopMode';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { MyHackathons } from './HackathonPage/MyHackathons';
import HomeIcon from '@mui/icons-material/Home';
import { PageContainer } from '../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { AppBreadcrumbs, PageType } from '../components/Breadcrumbs/Buttons';

export default function Home() {

    const desktopMode = useDesktopMode();

    const { user, loading } = useAuth();
    if (loading) {
        return <PageContainer>
            <Loading />
        </PageContainer>;
    }

    if (!user) {
        return (
            <PageContainer className="home">
                <Stack spacing={2} direction={
                    desktopMode ? 'row' : 'column'
                }>
                    <Blurb />
                </Stack>
            </PageContainer>

        )
    }

    return (
        <PageContainer className="home">
            <Stack spacing={2} direction='column'>
                <AppBreadcrumbs crumbs={
                    [
                        {
                            type: PageType.Home,
                            name: 'Home'
                        }
                    ]
                } />
                <MyHackathons />
            </Stack>
        </PageContainer>
    )

}

const Blurb = () => {
    const { user, loading } = useAuth();

    return (
        <Box py={2} className="blurb site-content">
            <div className="hero-image"></div>
            <Typography variant="h1">Let's organize your next Hackathon</Typography>
            <div className="cta">
                {
                    !user && <Button variant="contained" onClick={() => login()}>Log in or sign up</Button>
                }

            </div>
        </Box>
    )
}
