import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { getVote, votesAtom } from '../services/vote.service';

export const useVote = (hackathonId: string | undefined, ideaId: string | undefined) => {

    const [voteRequest, setVoteRequest] = React.useState<number>(0)
    useEffect(() => {
        if (hackathonId && ideaId && voteRequest >= 0) {
            getVote(hackathonId, ideaId)
        }

    }, [hackathonId, ideaId, voteRequest])

    const reload = () => {
        setVoteRequest(voteRequest + 1)
    }

    const votes = useAtomValue(votesAtom);

    const vote = useMemo(() => {
        if (!hackathonId || !ideaId) return undefined
        return votes[ideaId]
    }, [hackathonId, ideaId, votes])


    return {
        vote,
        reload
    }
}