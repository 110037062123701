import React, { lazy, useMemo } from 'react';
import { deleteIdea, disqualify, IdeaOptions, IdeaPayload, markQualified, unarchiveIdea, updateIdeas } from '../../services/idea.service';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { Markdown } from '../Markdown/Markdown';
import { Link } from 'react-router-dom';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import Alert from '@mui/material/Alert/Alert';
import Stack from '@mui/material/Stack/Stack';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import TextField from '@mui/material/TextField/TextField';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import DialogActions from '@mui/material/DialogActions/DialogActions';

const Editor = lazy(() => import('../Editor/Editor'));
export const IdeaSummary = ({
    hackathonId,
    idea,
    isInTeam,
    isOrganizer,
    canEdit,
    canDelete,
    onEdit,
    onDelete,
    onUnarchive
}: {
    hackathonId: string
    idea: IdeaPayload
    isInTeam: boolean
    isOrganizer: boolean
    canDelete?: boolean
    canEdit?: boolean
    onDelete?: () => void
    onEdit?: () => void
    onUnarchive?: () => void
}) => {

    const desktopMode = useDesktopMode();

    const description = useMemo(() => DOMPurify.sanitize(idea.description), [idea.description]);
    const submittedTime = useMemo(() => dayjs(idea.submittedAt).fromNow(), [idea.submittedAt]);
    const updatedTime = useMemo(() => dayjs(idea.lastUpdatedAt).fromNow(), [idea.lastUpdatedAt]);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | null>(null);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);

    const [editIdea, setEditIdea] = React.useState<IdeaOptions>({
        title: idea.title,
        description: idea.description
    });

    return (
        <>

            {
                error && <Alert severity='error'>{error.message}</Alert>
            }
            {
                !showEditForm && <Stack spacing={2} direction="column">

                    {
                        idea.disqualified && <Alert severity="error">Disqualified</Alert>
                    }
                    {
                        idea.archived && <Alert severity="warning">Archived</Alert>
                    }
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center">
                            {
                                isInTeam && <Tooltip title={'My team'}>
                                    <StarIcon color='primary' />
                                </Tooltip>
                            }

                            <h3><Link to={`/h/${hackathonId}/idea/${idea._id}`}>{idea.title}</Link></h3>
                        </Stack>
                        <Stack spacing={1} direction="row">
                            {
                                desktopMode && isOrganizer && idea.disqualified && <Button onClick={() => {
                                    setLoading(true);
                                    markQualified(hackathonId, idea._id).then(() => {
                                        onEdit && onEdit();
                                    }, (err) => {
                                        setError(err);
                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                }}>Requalify</Button>
                            }
                            {
                                desktopMode && isOrganizer && !idea.disqualified && <Button color="error" onClick={() => {
                                    setLoading(true);
                                    disqualify(hackathonId, idea._id).then(() => {
                                        onEdit && onEdit();
                                    }, (err) => {
                                        setError(err);
                                    }).finally(() => {
                                        setLoading(false);
                                    })
                                }}>Disqualify</Button>
                            }
                            {
                                desktopMode && canDelete && !idea.archived && <Button disabled={loading} color={'error'} onClick={() => {
                                    // show a confirmation dialog
                                    setShowDeleteConfirmation(true);
                                }}>Delete</Button>
                            }
                            {
                                desktopMode && idea.archived && isOrganizer && <Button onClick={() => {
                                    // unarchive the idea
                                    unarchiveIdea(hackathonId, idea._id).then(() => {
                                        onUnarchive && onUnarchive();
                                    });
                                }}>Unarchive</Button>
                            }
                            {
                                desktopMode && canEdit && <Button onClick={() => {
                                    // edit the idea
                                    setEditIdea({
                                        title: idea.title,
                                        description: idea.description
                                    })
                                    setShowEditForm(true);
                                }}>Edit</Button>
                            }
                            {
                                !desktopMode && canDelete && <IconButton color="error" onClick={() => {
                                    // show a confirmation dialog
                                    setShowDeleteConfirmation(true);
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                            {
                                !desktopMode && canDelete && <IconButton color="primary" onClick={() => {
                                    // edit the idea// edit the idea
                                    setEditIdea({
                                        title: idea.title,
                                        description: idea.description
                                    })
                                    setShowEditForm(true);
                                }}><EditIcon /></IconButton>
                            }

                        </Stack>

                    </Stack>
                    <div style={{
                        color: 'gray',
                        fontSize: '0.8em'
                    }}>submitted {submittedTime} {updatedTime !== submittedTime && (`(Last modified ${updatedTime})`)} by <b>{idea.createdBy.username}</b></div>
                    <div style={{
                        boxShadow: '0 1px 4px rgba(0,0,0,0.2) inset',
                        padding: '8px',
                        borderRadius: '4px',
                        backgroundColor: '#f9f9f9'
                    }}>
                        <Markdown markdown={description} />
                    </div>

                </Stack>
            }
            {
                showEditForm && <Stack spacing={2} direction="column">
                    <TextField label="Title" value={editIdea.title} onChange={(e) => setEditIdea({ ...idea, title: e.target.value })} />
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Editor toolbar={true} value={editIdea.description} onChange={(value) => setEditIdea({ ...idea, description: value })} />
                    </React.Suspense>
                    <Stack direction={'row'} spacing={2}>
                        <Button onClick={() => {
                            setError(null);
                            // update the idea
                            setLoading(true);
                            updateIdeas(hackathonId, idea._id, editIdea).then(() => {
                                setShowEditForm(false);
                            }, (err) => {
                                setError(err);
                            }).finally(() => {
                                setLoading(false);
                            })
                        }}>Save</Button>
                        <Button disabled={loading} onClick={() => {
                            setShowEditForm(false);
                            setError(null);
                        }}>Cancel</Button>
                    </Stack>
                </Stack>
            }
            <DeleteConfirmation
                open={showDeleteConfirmation}
                onConfirm={() => {
                    // delete the idea
                    deleteIdea(hackathonId, idea._id).then(() => {
                        onDelete && onDelete();
                    }, (err) => {
                        setError(err);
                    }).finally(() => {
                        setShowDeleteConfirmation(false);
                        setLoading(false);
                    })
                }}
                onCancel={() => setShowDeleteConfirmation(false)} />
        </>

    )
}

const DeleteConfirmation = ({
    open,
    onConfirm,
    onCancel
}: {
    open: boolean,
    onConfirm: () => void,
    onCancel: () => void
}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>Delete Idea</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this idea?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>Yes</Button>
                <Button onClick={onCancel}>No</Button>
            </DialogActions>
        </Dialog>
    )
}