

/** Votes */

import { atom } from "jotai";
import { VotePayload } from "../models/vote.model";

import { store } from './atom.service';
import axiosService from "./axios.service";
import { convertDataToIdeaPayload } from "./idea.service";
import { convertDataToHackathonPayload } from "./hackathon.service";

// the string key is the ideaId, because the user should only see their own vote
export const votesAtom = atom<Record<string, VotePayload>>({});


const convertDataToVote = (data: any): VotePayload => {
    const payload: VotePayload = {
        _id: data._id,
        userId: data.userId,
        ideaId: data.ideaId,
        scores: data.scores,
        notes: data.notes || []
    }

    const mergedPayload = payload;

    store.set(votesAtom, votes => ({
        ...votes,
        [data.ideaId]: mergedPayload
    }));

    return mergedPayload;
}

// returns a vote and an idea
export const submitVote = async (hackathonId: string, ideaId: string, scores: number[], notes: string[]) => {
    // ensure scores and notes do not have null
    scores = scores.map(score => score || 0);
    notes = notes.map(note => note || '');
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/vote`, { scores, notes });
    const idea = convertDataToIdeaPayload(data.idea);
    const vote = convertDataToVote(data.vote);
    return { idea, vote };
}

export const getVote = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.get(`/api/hackathons/${hackathonId}/ideas/${ideaId}/vote`);

    return convertDataToVote(data);
}

export const aggregateVotes = async (hackathonId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/aggregate_results`);
    return convertDataToHackathonPayload(data);
}
