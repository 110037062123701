import Container from '@mui/material/Container/Container';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

export const PageContainer = ({
    className,
    sx,
    children
}: {
    className?: string,
    sx?: SxProps<Theme>
    children: React.ReactNode
}) => {
    return (
        <Container className={className} sx={{ ...sx, backgroundColor: '#fff', paddingBottom: 4 }}>
            {children}
        </Container>
    )
}