import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { useState } from 'react';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QRCode from "react-qr-code";

export const QRContainer = () => {
    const url = window.location.href;

    return <QRCode value={url} />

}

export const QRCodeDialog = () => {

    const [qrDialogOpen, setQRDialogOpen] = useState(false);

    return (
        <>
            <IconButton title={'Show QR Code'} onClick={() => {
                setQRDialogOpen(true);
            }}><QrCodeScannerIcon /></IconButton>
            <Dialog open={qrDialogOpen} onClose={() => {
                setQRDialogOpen(false);
            }}>
                <DialogContent>
                    <QRContainer />
                </DialogContent>
            </Dialog>
        </>
    )
}