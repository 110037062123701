
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { updateHackathon } from '../../services/hackathon.service';
import { useNavigate, useParams } from 'react-router-dom';
import { useHackathon } from '../../hooks/useHackathon';
import { Loading } from '../../components/Loading';
import { EditHackathonData, HackathonEditor } from '../../components/Hackathon/HackathonEditor';
import { PageContainer } from '../../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Alert from '@mui/material/Alert/Alert';

export const EditHackathon = () => {

    const { id } = useParams();

    const { hackathon, loading, error } = useHackathon(id);

    const [data, setData] = useState<EditHackathonData>({
        name: hackathon?.name || '',
        description: hackathon?.description || '',
        startDate: hackathon?.startDate ? dayjs(hackathon.startDate) : null,
        endDate: hackathon?.endDate ? dayjs(hackathon.endDate) : null,
        categories: hackathon?.categories || [],
        categoryMeta: hackathon?.categoryMeta || [],
        ideaSubmissionDeadline: hackathon?.ideaSubmissionDeadline ? dayjs(hackathon.ideaSubmissionDeadline) : null,
        inviteCode: hackathon?.inviteCode || '',
        domain: hackathon?.domain || '',
        preferredAuth: hackathon?.preferredAuth || '',
        minTeamSize: hackathon?.minTeamSize || 1,
        maxTeamSize: hackathon?.maxTeamSize || 4,
        blindJudgeScores: hackathon?.blindJudgeScores || false,
        anonymousJudgeScores: hackathon?.anonymousJudgeScores || false,
        participantScores: hackathon?.participantScores || false,
        ideaTemplate: hackathon?.ideaTemplate || ''
    })

    useEffect(() => {
        setData({
            name: hackathon?.name || '',
            description: hackathon?.description || '',
            startDate: hackathon?.startDate ? dayjs(hackathon.startDate) : null,
            endDate: hackathon?.endDate ? dayjs(hackathon.endDate) : null,
            categories: hackathon?.categories || [],
            categoryMeta: hackathon?.categoryMeta || [],
            ideaSubmissionDeadline: hackathon?.ideaSubmissionDeadline ? dayjs(hackathon.ideaSubmissionDeadline) : null,
            inviteCode: hackathon?.inviteCode || '',
            domain: hackathon?.domain || '',
            preferredAuth: hackathon?.preferredAuth || '',
            minTeamSize: hackathon?.minTeamSize || 1,
            maxTeamSize: hackathon?.maxTeamSize || 4,
            blindJudgeScores: hackathon?.blindJudgeScores || false,
            anonymousJudgeScores: hackathon?.anonymousJudgeScores || false,
            participantScores: hackathon?.participantScores || false,
            ideaTemplate: hackathon?.ideaTemplate || ''
        })
    }, [hackathon])

    const navigate = useNavigate();


    if (!id) {
        return <div>No id provided</div>
    }

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <PageContainer>
            <Stack spacing={2}>
                <h1>Error loading the hackathon</h1>
            </Stack>
        </PageContainer>
    }

    if (hackathon && hackathon?.votingPeriodOn) {
        return (
            <PageContainer>
                <Alert severity="error">Editing the hackathon is disabled while voting is live.</Alert>
            </PageContainer>
        )
    }

    return (
        <HackathonEditor
            headingLabel={`Editing ${data.name}`}
            submitLabel='Update Hackathon'
            data={data}
            setData={setData}
            onSubmit={() => {
                updateHackathon(id, {
                    ...data,
                    startDate: data.startDate?.toDate(),
                    endDate: data.endDate?.toDate(),
                    ideaSubmissionDeadline: data.ideaSubmissionDeadline?.toDate()
                }).then(res => {
                    navigate(`/h/${res.slug}`);
                }).catch(err => {
                    console.error(err);
                });
            }} />

    )
}