import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../services/useAuth';
import { Loading } from '../../components/Loading';
import { useAtom } from 'jotai';
import { getMyHackathons, HackathonPayload, hackathonsAtom } from '../../services/hackathon.service';
import { useDesktopMode } from '../../services/hooks/useDesktopMode';
import { HackathonSummary } from './HackathonSummary';
import CodeIcon from '@mui/icons-material/Code';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../components/PageContainer';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Button from '@mui/material/Button/Button';
import { uniqBy } from 'lodash';

export const MyHackathons = () => {
    const { user, loading } = useAuth();

    const desktopMode = useDesktopMode();

    const [hackathons] = useAtom(hackathonsAtom);

    const [loadingHackathons, setLoadingHackathons] = useState(false);

    useEffect(() => {
        if (user) {
            setLoadingHackathons(true);
            getMyHackathons().then(() => {
                setLoadingHackathons(false);
            }, (err) => {
                console.error(err);
                setLoadingHackathons(false);
            });
        }
    }, [user])

    const list: HackathonPayload[] = useMemo(() => {
        return uniqBy(Object.values(hackathons), "_id");
    }, [hackathons])

    if (loading) {
        return <PageContainer>
            <Loading />
        </PageContainer>
    }

    if (!user) {
        return <PageContainer>
            <h1>Not logged in</h1>
        </PageContainer>
    }

    return <PageContainer>
        <Stack spacing={2}>
            <h1>
                <Stack direction='row' spacing={1} alignItems="center">
                    <CodeIcon fontSize='large' />
                    <span>My Hackathons</span>
                </Stack>
            </h1>
            {
                list.length === 0 && <Stack direction="column">
                    <Typography>Looks like you're not part of any hackathons</Typography>
                    <Typography>Go ahead and join one or create your own!</Typography>
                    <Stack direction="row">
                        <Button variant="contained" component={Link} to={`/new`}>Create a hackathon</Button>
                    </Stack>
                </Stack>
            }
            {
                list.map(h => (
                    <Card key={`my-hackathon-${h._id}`} sx={{ backgroundColor: '#f0f0f0' }}>
                        <CardContent>
                            <HackathonSummary hackathon={h} />
                        </CardContent>
                    </Card>
                ))
            }
            <br />
        </Stack>

    </PageContainer>
}