
/**
 * Users
 */

import { atom } from "jotai";
import { store } from "./atom.service";
import { User } from "./auth.service";

export const usersAtom = atom<Record<string, User>>({});

store.set(usersAtom, {});

export const convertDataToUser = (data: any): User => {
    const payload: User = {
        _id: data._id,
        guid: data.guid,
        username: data.username,
        email: data.email,
        isAdmin: data.isAdmin ?? false
    }

    const mergedPayload = payload;

    store.set(usersAtom, users => ({
        ...users,
        [data._id]: mergedPayload
    }));

    return mergedPayload;
}
