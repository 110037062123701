
/**
 * Ideas
 */

import { atom } from "jotai";
import { store } from "./atom.service";
import { User } from "./auth.service";
import axiosService from "./axios.service";
import { CommentPayload, convertDataToComment } from "./comment.service";
import { convertDataToUser } from "./user.service";


export interface IdeaOptions {
    title: string;
    description: string;
}

export interface IdeaPayload {
    _id: string;
    title: string;
    createdBy: User;
    description: string;
    submittedAt: Date;
    lastUpdatedAt: Date;
    likedBy: User[];
    members: User[];
    comments: CommentPayload[];
    reactions: Array<{
        user: User;
        emoji: string;
    }>;
    disqualified: boolean;
    archived?: boolean;
}


export interface IdeaScorecard {
    ideaId: string;
    judgeVotes: Array<{
        judge?: string; // user id, could be empty string if anonymous
        score: number;
    }> // could be empty if blind
    participantVoteAverage: number;
    judgeVoteAverage: number;
}

export const ideasAtom = atom<Record<string, IdeaPayload>>({});

store.set(ideasAtom, {});

export const createIdea = async (hackathonId: string, options: IdeaOptions) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/new`, options);
    return convertDataToIdeaPayload(data);
}

export const updateIdeas = async (hackathonId: string, ideaId: string, options: IdeaOptions) => {
    const { data } = await axiosService.put(`/api/hackathons/${hackathonId}/ideas/${ideaId}`, options);
    return convertDataToIdeaPayload(data);
}

export const getIdea = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.get(`/api/hackathons/${hackathonId}/ideas/${ideaId}`);
    return convertDataToIdeaPayload(data);
}

export const convertDataToIdeaPayload = (data: any): IdeaPayload => {
    const payload: IdeaPayload = {
        _id: data._id,
        title: data.title,
        createdBy: convertDataToUser(data.createdBy),
        description: data.description,
        submittedAt: new Date(data.submittedAt),
        lastUpdatedAt: new Date(data.lastUpdatedAt),
        likedBy: data.likedBy.map(convertDataToUser),
        members: data.members.map(convertDataToUser),
        comments: data.comments.map(convertDataToComment),
        reactions: data.reactions.map((reaction: any) => ({
            user: convertDataToUser(reaction.user),
            emoji: reaction.emoji
        })),
        disqualified: data.disqualified ?? false,
        archived: data.archived ?? false
    }

    const mergedPayload = {
        ...store.get(ideasAtom)[data._id] ?? {},
        ...payload
    };

    store.set(ideasAtom, (ideas) => ({
        ...ideas,
        [data._id]: mergedPayload
    }));

    return mergedPayload;
}

export const likeIdea = async (hackathonId: string, ideaId: string) => {

    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/like`);
    return convertDataToIdeaPayload(data);
}

export const unlikeIdea = async (hackathonId: string, ideaId: string) => {

    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/unlike`);
    return convertDataToIdeaPayload(data);
}

export const joinIdea = async (hackathonId: string, ideaId: string) => {

    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/join`);
    return convertDataToIdeaPayload(data);
}

export const leaveIdea = async (hackathonId: string, ideaId: string) => {

    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/leave`);
    return convertDataToIdeaPayload(data);
}

export const removeMemberFromIdea = async (hackathonId: string, ideaId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/remove_member`, { email });
    return convertDataToIdeaPayload(data);
}

export const addMemberToIdea = async (hackathonId: string, ideaId: string, email: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/add_member`, { email });
    return convertDataToIdeaPayload(data);
}

export const deleteIdea = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.delete(`/api/hackathons/${hackathonId}/ideas/${ideaId}`);
    return convertDataToIdeaPayload(data);
}

export const unarchiveIdea = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/unarchive`);
    return convertDataToIdeaPayload(data);
}

export const addReactionToIdea = async (hackathonId: string, ideaId: string, emoji: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/addReaction`, { emoji });
    return convertDataToIdeaPayload(data);
}

export const removeReactionFromIdea = async (hackathonId: string, ideaId: string, emoji: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/removeReaction`, { emoji });
    return convertDataToIdeaPayload(data);
}

export const markQualified = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/qualify`);
    return convertDataToIdeaPayload(data);
}

export const disqualify = async (hackathonId: string, ideaId: string) => {
    const { data } = await axiosService.post(`/api/hackathons/${hackathonId}/ideas/${ideaId}/disqualify`);
    return convertDataToIdeaPayload(data);
}