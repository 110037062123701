
import React from 'react';
import './AppFooter.scss';
import Container from '@mui/material/Container/Container';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';

export const AppFooter = () => {

    return (
        <div className="app-footer">
            <Container>
                <Box sx={{py: 3, minHeight: 78 }}>
                    <Typography>&copy;2024 Hack in a Box</Typography>
                </Box>
            </Container>
        </div>
    )
}