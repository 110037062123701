import React, { lazy, useState } from 'react';

import { createHackathon } from '../../services/hackathon.service';
import { useNavigate } from 'react-router-dom';
import { EditHackathonData, HackathonEditor } from '../../components/Hackathon/HackathonEditor';

export const NewHackathon = () => {
    const [data, setData] = useState<EditHackathonData>({
        name: 'Untitled Hackathon',
        description: '',
        startDate: null,
        endDate: null,
        categories: [],
        categoryMeta: [],
        ideaSubmissionDeadline: null,
        inviteCode: '',
        domain: '',
        maxTeamSize: 4,
        minTeamSize: 2,
        participantScores: false,
        blindJudgeScores: false,
        anonymousJudgeScores: false,
        ideaTemplate: '',
        preferredAuth: ''
    })
    const navigate = useNavigate();

    return (
        <HackathonEditor
            headingLabel='Creating a new Hackathon'
            submitLabel='Create Hackathon'
            data={data}
            setData={setData}
            onSubmit={() => {

                const payload = {
                    ...data,

                    startDate: data.startDate?.toDate(),
                    endDate: data.endDate?.toDate(),
                    ideaSubmissionDeadline: data.ideaSubmissionDeadline?.toDate(),
                }
                createHackathon(payload).then(res => {
                    navigate(`/h/${res.slug}`);
                }).catch(err => {
                    console.error(err);
                });
            }} />

    )
};