import React, { useEffect, useState } from 'react';
import { User, authAtom, getProfile } from './auth.service';

import { useAtomValue } from 'jotai';
export const useAuth = () => {

    // const [user, setUser] = useState<User | null>(AuthService.user)
    const user = useAtomValue(authAtom);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (user === 0 || !user) {
            setLoading(true);
            getProfile().then(() => {
                setLoading(false);
            }, () => {
                setLoading(false);
            })
        } else if (user) {
            setLoading(false);
        }
    }, [user])
    return {
        user,
        loading
    }
}