import Box from '@mui/material/Box/Box';
import Fab from '@mui/material/Fab/Fab';
import Fade from '@mui/material/Fade/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger/useScrollTrigger';
import React from 'react';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const ScrollTopButton = (
    {
        window
    }: {
        window?: () => Window;
    }
) => {

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };
    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Fab size="small" color={'info'} aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Fade>
    );
}