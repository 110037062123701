import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { User } from '../../services/auth.service';
import { addJudgeToHackathon, addOrganizerToHackathon, HackathonPayload, removeJudgeFromHackathon, removeOrganizerFromHackathon, removeParticipantFromHackathon } from '../../services/hackathon.service';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton/IconButton';
import MenuItem from '@mui/material/MenuItem/MenuItem';
export const MemberMenu = ({ user, hackathon, actions }: {
    user: {
        name: string,
        email: string,
        team?: string,
        roles: string[]
    },
    hackathon: HackathonPayload,
    actions: Array<'addJudge' | 'removeJudge' | 'addOrganizer' | 'removeOrganizer' | 'removeParticipant'>
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = useMemo(() => user.email, [user]);


    const addJudge = (email: string) => {
        if (!hackathon) {
            return;
        }
        // Add judge to hackathon
        addJudgeToHackathon(hackathon._id, email);
    }

    const removeJudge = (email: string) => {
        if (!hackathon) {
            return;
        }
        // Remove judge from hackathon
        removeJudgeFromHackathon(hackathon._id, email);
    }

    const addOrganizer = (email: string) => {
        if (!hackathon) {
            return;
        }
        // Add organizer to hackathon
        addOrganizerToHackathon(hackathon._id, email);

    }
    const removeOrganizer = (email: string) => {
        if (!hackathon) {
            return;
        }
        // Remove organizer from hackathon
        removeOrganizerFromHackathon(hackathon._id, email);
    }

    const removeParticipant = (email: string) => {
        if (!hackathon) {
            return;
        }
        // Remove participant from hackathon
        removeParticipantFromHackathon(hackathon._id, email);
    }

    return (
        <div>
            <IconButton
                id={'member-button-' + id}
                aria-controls={open ? 'member-menu-' + id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                title={'More options for ' + user.name}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={'member-menu-' + id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'member-button-' + id,
                }}
            >
                {
                    actions.includes('addJudge') && <MenuItem onClick={() => {
                        addJudge(user.email);
                        handleClose();
                    }}>Add as judge</MenuItem>
                }
                {
                    actions.includes('removeJudge') && <MenuItem onClick={() => {
                        removeJudge(user.email);
                        handleClose();
                    }}>Remove as judge</MenuItem>
                }
                {
                    actions.includes('addOrganizer') && <MenuItem onClick={() => {
                        addOrganizer(user.email);
                        handleClose();
                    }}>Add as organizer</MenuItem>
                }
                {
                    actions.includes('removeOrganizer') && <MenuItem onClick={() => {
                        removeOrganizer(user.email);
                        handleClose();
                    }}>Remove as organizer</MenuItem>
                }
                {
                    actions.includes('removeParticipant') && <MenuItem onClick={() => {
                        removeParticipant(user.email);
                        handleClose();
                    }}>Remove from hackathon</MenuItem>
                }
            </Menu>
        </div>
    )

}