import axios from "./axios.service";
import { atom } from 'jotai';
import { store } from "./atom.service";

axios.defaults.withCredentials = true;

let state: {
    user: User | undefined,
    authenticated: boolean,
} = {
    user: undefined,
    authenticated: false
}


export type User = {
    _id: string;
    guid: string;
    username: string;
    email: string;
    isAdmin: boolean;
}

export const authAtom = atom<User | null | 0>(0);

export function login(contPath?: string, authmethod?: string) {
    const curPath = window.location.pathname;
    let path = (contPath ? contPath : curPath);
    if (!path.startsWith('/')) path = '/' + path;
    let url = window.location.href;
    let next = `/auth/login?cont=${encodeURIComponent(url)}`
    if (authmethod) {
        next += `&method=${authmethod}`
    }
    window.location.href = next;
}

export function logout() {
    axios.post('/auth/logout').then(() => {
        state = {
            ...state,
            user: undefined,
            authenticated: false
        }
        store.set(authAtom, null);
        window.location.reload();
    })
}

export async function getProfile() {
    const { data: userData } = await axios.get('/api/profile');
    if (userData.authenticated !== false) {
        state = {
            ...state,
            user: userData.user,
            authenticated: userData.authenticated,
        }

    }
    if (!userData.user) {
        store.set(authAtom, null)
    } else {
        store.set(authAtom, userData.user)
    }

    return state.user;
}

export function getUser() {
    return JSON.parse(JSON.stringify(state.user)); // cheap alternative to lodash clonedeep
}

setInterval(() => {
    if (!state.authenticated) {
        getProfile();
    }
}, 1000 * 60 * 5) // 5 minutes